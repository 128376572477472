/*------------------------------------*\
    $SLIDER
\*------------------------------------*/

.slider {
    background-color: $colour-ui;

    .slick-arrow,
    .slick-dots {
        position: absolute;
    }

    .slick-dots {
        margin-bottom: 0;
        bottom: 14px;
        left: 0;
        right: 0;

        button {
            &::before {
                background-color: $colour-ui-light;
                box-shadow: 0 3px 20px rgba(#000, .1);
            }
        }
    }

    .slick-arrow {
        top: 50%;
        transform: translateY(-50%);
        color: $colour-ui-light;
        z-index: 1;
        width: 60px;
        height: 60px;

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 20px;
            height: 40px;
            filter: drop-shadow(0 3px 8px rgba(#000, .4));
            transition: transform $base-anim-speed ease-in-out;
        }

        @include media-query(nav) {
            width: 100px;
            height: 100px;
        }
    }

    .slick-prev {
        left: 0;

        &:hover {
            .icon {
                transform: translate(calc(-50% - 5px), -50%);
            }
        }
    }

    .slick-next {
        right: 0;

        &:hover {
            .icon {
                transform: translate(calc(-50% + 5px), -50%);
            }
        }
    }
}
