/*------------------------------------*\
    $GRID
\*------------------------------------*/

// Define grid gutter sizes
$grid-gutter-width:	21px !default;


// Define mixins
@mixin grid($gutter-width: $grid-gutter-width, $mode: flex) {
    margin-left: (0 - $gutter-width);
    display: $mode;
    flex-wrap: wrap;
}

@mixin grid-width($fraction, $gutter-width: $grid-gutter-width) {
    @if ($fraction == auto) {
        flex: 0 1 auto;
        width: auto;
    } @else {
        @if ($gutter-width != 0px) {
            width: calc(#{percentage($fraction)} - #{$gutter-width});
        } @else {
            width: #{percentage($fraction)};
        }
    }
}

@mixin grid-item($fraction: 1, $gutter-width: $grid-gutter-width) {
    @include grid-width($fraction, $gutter-width);
    margin-left: $gutter-width;
}


/**
 * Base grid object
 */

.grid {
    @include grid;
    margin-bottom: 0;
}

    .grid-item {
        @include grid-item;
    }


/**
 * Gutter modifiers
 */

.grid-flush {
    margin-left: 0;


    > .grid-item {
        @include grid-item(1, 0);
    }
}

.grid-sml {
    margin-left: -10px;

    > .grid-item {
        @include grid-item(1, 10px);
    }
}

.grid-med {
    margin-left: -32px;

    > .grid-item {
        @include grid-item(1, 32px);
    }
}

.grid-lrg {
    margin-left: 0 - double($base-spacing-unit);

    > .grid-item {
        @include grid-item(1, double($base-spacing-unit));
    }
}



/**
 * Reverse source order
 */

.grid-reverse {
    flex-direction: row-reverse;
}


/**
 * Alignment modifiers
 */

.grid-center {
    justify-content: center;
}

.grid-bottom {
    align-items: flex-end;
}

.grid-middle {
    align-items: center;
}

.grid-item-top {
    align-self: flex-start;
}
