/*------------------------------------*\
    $ACCORDION
\*------------------------------------*/

.accordion {
    &:not(:last-child) {
        margin-bottom: 5px;
    }
}

.accordion-toggle {
    @include font-size(16px);
    position: relative;
    display: block;
    width: 100%;
    padding: halve($base-spacing-unit) 20px;
    background-color: $colour-secondary;
    color: $colour-ui-light;
    font-weight: $font-weight-medium;
    border-radius: 4px;

    .icon {
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        width: 10px;
        height: 5px;
        transition: transform $base-anim-speed $anim-ease-in-out;
    }

    &.is-active {
        .icon {
            transform: translateY(-50%) rotate(0.5turn);
        }
    }

    .accordion:nth-of-type(even) & {
        background-color: #ffb545;
    }
}

.accordion-body {
    display: none;
    overflow: hidden;
    padding-left: 20px;
    padding-right: 20px;

    > :last-child {
        margin-bottom: 0;
    }

    &::before,
    &::after {
        content: "";
        display: block;
        height: 14px;
    }
}
