/*------------------------------------*\
    $COUNTDOWN
\*------------------------------------*/

.countdown {
    @include font-size(13px);
    text-align: center;

    span {
        @include font-size(18px);
        font-weight: $font-weight-medium;
        padding-left: 3px;
        padding-right: 3px;
    }
}
