/*------------------------------------*\
    $LINK-BARE
\*------------------------------------*/

.link-bare {
    color: currentColor;

    &:hover {
        color: $colour-tertiary;
    }
}
