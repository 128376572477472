/*------------------------------------*\
    $TOUR
\*------------------------------------*/

.tour-intro {
    color: #666;
}

.tour {
    background-color: $colour-ui;

    &::before {
        content: "";
        display: block;
        padding-top: 140%;

    }

    @include media-query(lap) {
        min-height: 500px;
        max-height: 95vh;

        &::before {
            padding-top: 55%;
        }
    }

    iframe {
        display:block !important;
        object-position:0%;
    }
}

.tour-360-wrapper {
    margin-bottom: $base-spacing-unit;

    @include media-query(desk) {
        margin-top: double($base-spacing-unit);
    }
}
