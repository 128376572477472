/*------------------------------------*\
    $CHECK
\*------------------------------------*/


.check {
    display: block;
    position: relative;
}

.check-control {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked ~ .check-body {
        &::after {
            opacity: 1;
        }
    }

    &:focus ~ .check-body {
        .has-tabbing-user &::before {
            outline: 2px dotted rgba($colour-primary, .8);
            outline-offset: 2px;
        }

        .has-tabbing-user .band-primary &::before {
            outline-color: rgba($colour-ui-light, .8);
        }
    }
}

.check-body {
    position: relative;
    display: flex;
    align-items: center;

    &::before,
    &::after {
        content: "";
    }

    &::before {
        align-self: flex-start;
        display:block;
        width: 32px;
        height: 32px;
        border: 1px solid;
        margin-right: 8px;
        border-radius: 3px;
    }

    &::after {
        position: absolute;
        top: 16px;
        left: 16px;
        width: 9px;
        height: 16px;
        border-right: 2px solid;
        border-bottom: 2px solid;
        opacity: 0;
        margin-top: -2px;
        transform: translate(-50%, -50%) rotate(45deg);
        transition: opacity $base-anim-speed;
    }

    .is-invalid & {
        color: $colour-invalid;
    }

    .band-primary .is-invalid &,
    .widget {
        color: currentColor;
    }

    @include media-query(palm) {
        .modal & {
            color: currentColor;
        }
    }
}

.check-text {
    flex: 1;
}
