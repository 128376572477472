/*------------------------------------*\
    $NAV
\*------------------------------------*/

.nav-toggle {
    position: relative;
    width: 32px;
    height: 32px;
    z-index: 2;
    transition: color $base-anim-speed $base-anim-speed, background-color $base-anim-speed;

    &.is-active {
        transition-delay: 0s;
        color: $colour-ui-light;
    }

    .header-sticky & {
        transition-delay: 0s;
    }

    @include media-query(nav) {
        .header:not(.header-sticky) & {
            display: none;
        }
    }
}


@keyframes burger-before {
    0% { transform: translateY(-6px); }
    50% { transform: none; }
    100% { transform: rotate(-45deg); }
}
@keyframes burger-before-rev {
    100% { transform: translateY(-6px); }
    50% { transform: none; }
    0% { transform: rotate(-45deg); }
}

@keyframes burger-after {
    0% { transform: translateY(6px); }
    50% { transform: none; }
    100% { transform: rotate(45deg); }
}
@keyframes burger-after-rev {
    100% { transform: translateY(6px); }
    50% { transform: none; }
    0% { transform: rotate(45deg); }
}

.nav-toggle-icon {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &,
    &::before,
    &::after {
        position: absolute;
        transition: background-color 0s halve($base-anim-speed);
        background-color: $base-text-colour;
        width: 20px;
        height: 3px;
        border-radius: 5px;
    }

    &::before,
    &::after {
        content: "";
        top: 0;
        left: 0;
    }

    &::before {
        transform: translateY(-6px);
    }

    &::after {
        transform: translateY(6px);
    }

    .is-active > & {
        background-color: transparent;

        &::before,
        &::after {
            background-color: $colour-ui-light;
        }

        &::before {
            animation: burger-before $base-anim-speed forwards;
        }

        &::after {
            animation: burger-after $base-anim-speed forwards;
        }
    }

    .is-exiting > & {
        &::before {
            animation: burger-before-rev $base-anim-speed forwards;
        }

        &::after {
            animation: burger-after-rev $base-anim-speed forwards;
        }
    }
}

.nav-toggle-close {
    @include font-size(18px, 32px);
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 35px;
    opacity: 0;
    font-weight: $font-weight-medium;
    transform: translateX(-5px);
    transition: opacity $base-anim-speed, transform $base-anim-speed;
    pointer-events: none;

    .is-active > & {
        opacity: 1;
        transform: none;
        pointer-events: auto;
    }
}

.nav {
    .header-sticky & {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: $colour-tertiary;
        padding: 118px 108px 52px;
        visibility: hidden;
        opacity: 0;
        transition: opacity $base-anim-speed, visibility 0s $base-anim-speed;
        z-index: 1;

        &.is-active {
            overflow: auto;
            visibility: visible;
            transition-delay: 0s;
            opacity: 1;
        }
    }

    @include media-query(nav-portable) {
        position: absolute;
        top: 0;
        right: 100%;
        width: 100%;
        max-width: 320px;
        height: 100vh;
        padding: 74px 45px;
        background-color: $colour-tertiary;
        outline: none;
        overflow: auto;
        transition: transform $base-anim-speed $anim-ease-in;

        &.is-active {
            transform: translateX(100%);
            transition-timing-function: $anim-ease-out;
        }
    }

    @include media-query(nav) {
        flex-grow: 1;
    }
}
.nav-list {
    .header-sticky & {
        margin-left: -$base-spacing-unit;
        flex-wrap: wrap;
        align-items: flex-start;
        height: auto;
        margin-bottom: 48px;
    }

    @include media-query(nav) {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        height: 48px;
        margin-left: -16px;

        @media (min-width: 1240px) {
            margin-left: -32px;
        }
    }
}

.nav-item {
    @include media-query(nav-portable) {
        &:not(:last-child) {
            margin-bottom: halve($base-spacing-unit);
        }
    }

    @include media-query(nav) {
        margin-left: 16px;

        .nav-secondary & {
            margin-left: 26px;
        }

        .header-sticky & {
            width: calc(25% - #{$base-spacing-unit});
            margin-left: 24px;
        }

        @media (min-width: 1240px) {
            margin-left: 32px;
        }
    }
}

.nav-home {
    @include media-query(nav) {
        display: none;
    }
}

.nav-link {
    @include font-size(16px);
    display: inline-block;
    font-weight: $font-weight-medium;

    &,
    .header-sticky & {
        color: $colour-ui-light;
    }

    @include media-query(nav-portable) {
        &.is-current {
            text-decoration: underline;
        }
    }

    @include media-query(nav) {
        color: $colour-tertiary;
        white-space: nowrap;
        padding: 6px 0;

        .nav-secondary & {
            @include font-size(14px);
            font-weight: $font-weight-light;
        }

        .header-sticky &:not(.nav-contact-link) {
            @include font-size(24px, 32px);
            font-weight: $font-weight-medium;
            white-space: normal;
        }

        &.is-current {
            color: $colour-primary;

            .header-sticky & {
                color: $colour-ui-light;
            }
        }

        @media (max-width: 1169px) {
            @include font-size(14px);
        }
    }
}

.nav-contact {
    @include media-query(nav) {
        margin-left: auto;

        .nav-logout + & {
            margin-left: 32px;
        }

        @media (max-width: 969px) {
            display: none;
        }
    }
}

.nav-logout {
    @include media-query(nav) {
        margin-left: auto;
    }
}

.nav-logout-link,
.nav-contact-link {
    .header-sticky & {
        margin-left: auto;
        position: relative;
        z-index: 2;

        .has-active-nav & {
            &:hover {
                background-color: $colour-ui-light;
                color: $colour-tertiary;
            }
        }
    }

    @include media-query(nav) {
        @include font-size(14px, 20px);
        text-align: center;
        margin-left:24px;
        padding: 14px $base-spacing-unit 15px;
        min-width: 184px;
        background-color: $colour-secondary;
        font-weight: $font-weight-bold;
        color: $colour-ui-light;
        border-radius: 3px;
        transition: background-color $base-anim-speed, border-color $base-anim-speed, color $base-anim-speed;

        .wf-active & {
            font-weight: $font-weight-medium;
        }

        &:hover {
            background-color: $colour-tertiary;
            color: $colour-ui-light;
        }

        @media (max-width: 1099px) {
            min-width: 0;
        }

        .nav-logout ~ .nav-contact & {
            padding: 13px $base-spacing-unit 14px;
            border: 1px solid $colour-tertiary;
            background-color: transparent;
            color: $colour-tertiary;

            &:hover {
                background-color: $colour-tertiary;
                color: $colour-ui-light;
            }
        }
    }
}

.nav-search {
    @include media-query(nav-portable) {
        display: none;
    }
}

.nav-secondary {
    .header-sticky & {
        position: static;
        // padding-top: double($base-spacing-unit);

        &::before {
            content: "";
            display: block;
            width: calc(100% - #{$base-spacing-unit});
            border-top: 2px solid $colour-ui-light;
            margin-left: $base-spacing-unit;
            margin-bottom: double($base-spacing-unit);
        }
    }

    @include media-query(nav-portable) {
        padding-top: $base-spacing-unit;
        border-top: 1px solid $colour-ui-light;
    }

    @include media-query(nav) {
        position: absolute;
        top: 6px;
        right: 24px;
        height: auto;
    }
}

.nav-secondary-contact {
    @include media-query(nav-portable) {
        display: none;
    }
}

.nav-sticky {
    .header:not(.header-sticky) & {
        display: none;
    }
}

.nav-sticky-hide {
    .header-sticky & {
        display: none;
    }
}
