/*------------------------------------*\
    $PAGINATION
\*------------------------------------*/

.pagination,
.pagination-list {
    display: flex;
    align-items: center;
}

.pagination {
    margin-bottom: $base-spacing-unit;
    justify-content: space-between;

    @include media-query(desk) {
        margin-top: 60px - $base-spacing-unit;
    }
}

.pagination-prev,
.pagination-next {
    color: $base-text-colour;
    transition: color $base-anim-speed;
    width: 100px;

    .icon {
        width: 20px;
        height: 39px;
        margin-top: -2px;
    }

    &:hover {
        color: $colour-primary;
        text-decoration: none;
    }

    &.is-disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}

.pagination-prev {
    .icon {
        margin-right: 10px;
    }
}

.pagination-next {
    text-align: right;

    .icon {
        margin-left: 10px;
    }
}

.pagination-list {
    margin-bottom: 0;

    @include media-query(palm) {
        display: none;
    }
}

.pagination-number {
    width: 28px;
    height: 28px;
    line-height: 28px;
    display: block;
    text-align: center;
    color: $base-text-colour;
    transition: background-color $base-anim-speed, color $base-anim-speed;

    &:hover,
    &.is-active {
        color: $colour-primary;
    }
}

.pagination-ellipsis {
    width: 28px;
    text-align: center;
}
