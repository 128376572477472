/*------------------------------------*\
    $HEADER
\*------------------------------------*/

.header-threshold {
    @include media-query(nav-portable) {
        padding-top: 65px;
    }
}

.header {
    background-color: $colour-ui-light;
    @include media-query(nav-portable) {
        position: fixed;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
    }
}

.header-ui {
    display: flex;
    align-items: center;

    .header-sticky & {
        padding: 9px 16px;
        height: auto;
        position: relative;
        align-items: center;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            background-color: rgba($colour-tertiary, .75);
            visibility: hidden;
            opacity: 0;
            transition: opacity $base-anim-speed, visibility 0s $base-anim-speed;

            .has-active-nav & {
                opacity: 1;
                visibility: visible;
                transition-delay: 0s;
            }
        }
    }

    .page-booking & {
        box-shadow: 0 0 10px rgba(#000, .1);
    }

    @include media-query(nav-portable) {
        justify-content: space-between;
        padding: 10px 14px;
        background-color: $colour-ui-light;
        box-shadow: 0 0 10px rgba(#000, .1);

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 100%;
            z-index: 2;
            width: 100%;
            height: 100%;
            max-width: 320px;
            background-color: rgba($colour-tertiary, .75);
            transition: transform $base-anim-speed $anim-ease-in;

            .has-active-nav & {
                transform: translateX(100%);
                transition-timing-function: $anim-ease-out;
            }
        }
    }

    @include media-query(nav) {
        padding: 26px 24px 10px 27px;
        height: 108px;
        align-items: flex-end;
    }
}

.header-logo {
    .header-sticky & {
        margin: 0;
        position: absolute;
        left: 66px;
        top: 50%;
        transform: translateY(-50%);

        img {
            width: 173px;
        }
    }

    @include media-query(nav) {
        margin-right: 46px;
        margin-bottom: 7px;

        @media (min-width: 1040px) {

            img {
                width: 244px;
            }
        }
    }
}

.header-logo-custom {
    object-fit: contain;
    font-family: 'object-fit: contain';
    width: auto !important;
    max-width: 200px;
    height: 47px;

    @include media-query(nav) {
        max-width: 173px;
        max-height: 70px;

        .header-sticky & {
            max-width: 200px;
            max-height: 47px;
        }

        @media (min-width: 1040px) {
            max-width: 244px;
        }
    }
}

.header-search {
    @include media-query(nav) {
        display: none;
    }
}

.header-contact {
    display: none;

    @include media-query(nav) {
        .header-sticky & {
            display: block;
        }
    }
}

.header-actions {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 10px rgba(#000, .1);

    .btn {
        padding-left: 14px;
        padding-right: 14px;
    }

    @include media-query(nav) {
        display: none;
    }
}


/**
 * Sticky stuff
 */

.header-sticky {
    position: fixed;
    z-index: 3;
    top:-100%;
    left: 0;
    width: 100%;
    background-color: $colour-ui-light;
    box-shadow: 0 0 10px rgba(#000, .1);
    visibility: hidden;
    transition: top $base-anim-speed $anim-ease-in, visibility 0s $base-anim-speed * 1.5;

    &.is-sticky {
        visibility: visible;
        top:0;
        transition-delay: 0s;
        transition-timing-function: $anim-ease-out;
    }

    .page-booking ~ & {
        display: none;
    }

    @include media-query(nav-portable) {
        display: none;
    }
}
