/*------------------------------------*\
    $KEY
\*------------------------------------*/

.key {
    @include font-size(13px);
}

.key-item {
	padding-left: $base-spacing-unit + 6px;
	position: relative;

    &:not(:last-child) {
        margin-bottom: 6px;
    }
}

.key-colour {
	display: inline-block;
	width: $base-spacing-unit;
	height: $base-spacing-unit;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
    border-radius: 2px;
}

.key-available {
	background-color: rgba(149,255,1,0.5);
}

.key-selected {
    background-color: $colour-selected;
}

.key-check {
    background: repeating-linear-gradient(-45deg, transparent, transparent 25%, rgba($colour-primary, .25) 25%, rgba($colour-primary, .25) 50%, transparent 50%) $colour-selected top left;
    background-size: 30px 30px;
}
