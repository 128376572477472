/*------------------------------------*\
    $UPLOAD
\*------------------------------------*/

.upload {
    position: relative;
    display: inline-block;
}

.upload-control {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.upload-body {
    display: flex;
    align-items: center;
    font-weight: $font-weight-medium;

    &[data-filename] {
        overflow: hidden;
        font-size: 0;

        &::after {
            content: attr(data-filename);
            font-size: $base-font-size;
        }
    }
}

.upload-figure {
    position: relative;
    width: double($base-spacing-unit);
    height: double($base-spacing-unit);
    margin-right: halve($base-spacing-unit);
    background-color: $colour-ui-light;
    color: $colour-tertiary;
    border-radius: 3px;

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 23px;
        height: 33px;
    }
}
