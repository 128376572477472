/*------------------------------------*\
    $ILLUSTRATED-MAP
\*------------------------------------*/

.illustrated-map {
    position:relative;
    z-index:1;

    @include media-query(palm) {
        height: 365px;
    }

    &:before {
        position:relative;
        z-index:1;
		content:"";
		display:block;
        padding-top:50%;
	}

    .google_map {
        position:absolute;
        top:0; left:0; right:0; bottom:0;
        z-index:2;
    }
}

.illustrated-map-key {
    // height: 450px;
    overflow: auto;
    padding: 0 32px 32px;

    @include media-query(palm) {
        display: none;
    }
}

.illustrated-map-item {
    display: flex;
    position: relative;
    align-items: center;
    padding-right: 36px;

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    .illustrated-map-box & {
        margin-bottom: 8px;
    }

    // &:hover {
    //     .illustrated-map-toggle {
    //         opacity: 0.5;
    //     }
    // }
}

.illustrated-map-toggle {
    @include font-size(12px);
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.5;
    // transition: opacity $base-anim-speed;
}

.illustrated-map-icon {
    flex-shrink: 0;
    margin-right: 8px;
    align-self: flex-start;

    .illustrated-map-box &,
    .modal-content & {
        width: 30px;
        height: 30px;
    }
}

.illustrated-map-body {
    flex-grow: 1;
}

.illustrated-map-box {
    @include font-size(14px);
    font-family: $base-font;
    padding: 20px 20px 12px;
    background-color: $colour-tertiary;
    color: $colour-ui-light;

    &,
    .figure {
        width: 360px;
        margin: 0;
    }

    p {
        margin-bottom: 16px,
    }

    .illustrated-map-figure {
        margin: -20px -20px 20px;
    }
}

.illustrated-map-header {
    color: $colour-ui-light;
    margin-bottom: 16px;
}
