/*------------------------------------*\
    $AJAX
\*------------------------------------*/

@keyframes ajax-rotate {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.ajax,
.ajax-field .field-wrapper {
    position: relative;

    &::before,
    &::after {
        content: "";
        display: block;
        visibility: hidden;
        opacity: 0;
        z-index: 1;
        transition: opacity $base-anim-speed, visibility 0s $base-anim-speed;
    }

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($base-background-colour, .75);
    }

    &::after {
        position: fixed;
        border: 3px solid transparent;
        border-top-color: $colour-primary;
        border-bottom-color: $colour-primary;
        border-radius: 99px;
        width: 50px;
        height: 50px;
        top: 50vh;
        left: 50vw;
        transform: translate(-50%, -50%);
        animation: ajax-rotate 0.6s infinite linear;
    }

    &.is-loading,
    &.is-failed {
        &::before,
        &::after {
            opacity: 1;
            visibility: visible;
            transition-delay: 0s;
        }
    }

    &.is-failed {
        &::after {
            opacity: 0;
            visibility: hidden;
            transition-delay: 0s, $base-anim-speed;
        }
    }
}

.ajax-block {
    min-height: 50px;

    &::after {
        position: absolute;
        top: 50%;
        left: 50%;
    }

    &.post-list {
        &::after {
            top: calc(50% - 10.5px);
            left: calc(50% + 10.5px);
        }
    }
}

.ajax-field .field-wrapper,
.ajax-calendar {
    &::before {
        background-color: rgba($colour-primary, .75);
    }

    &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        border-top-color: $colour-ui-light;
        border-bottom-color: $colour-ui-light;
    }
}

.ajax-field .field-wrapper {
    &::before {
        background-color: rgba($colour-primary, .5);
    }

    &::after {
        width: 20px;
        height: 20px;
        border-width: 2px;
    }
}

.ajax-message {
    @include font-size(12px, 1.5);
    position: absolute;
    top: 50%;
    left: $base-spacing-unit;
    right: $base-spacing-unit;
    text-align: center;
    opacity: 0;
    z-index: 2;
    transform: translateY(32px);
    visibility: hidden;
    transition: opacity $base-anim-speed, visibility 0s $base-anim-speed;

    .is-loading > &,
    .is-failed > & {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
    }

    .is-failed > & {
        transform: translateY(-50%);
    }

    button {
        text-decoration: underline;
    }
}
