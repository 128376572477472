/*------------------------------------*\
    $BLEED
\*------------------------------------*/

.bleed-palm {
    @include media-query(palm) {
        margin-left: -38px;
        margin-right: -38px;
    }
}
