/*------------------------------------*\
    $RESULTS
\*------------------------------------*/

.results-title {
    @include font-size(24px, 34px);
    font-family: $base-fallback-font;
    font-weight: $font-weight-normal;
    color: $colour-tertiary;

    .wf-active & {
        font-family: $base-font;
    }

    @include media-query(desk) {
        margin-bottom: 60px;
    }
}

.results-section {
    margin-bottom: 60px - $base-spacing-unit;
}

.results-page-title {
    @include font-size(14px);
    margin-bottom: halve($base-spacing-unit);
    font-family: $base-fallback-font;
    font-weight: $font-weight-medium;

    .wf-active & {
        font-family: $base-font;
    }
}

.results-page-description {
    p {
        margin-bottom: halve($base-spacing-unit);
    }
}

.results-page-link {
    font-weight: $font-weight-medium;
}
