/*------------------------------------*\
    $PRODUCT
\*------------------------------------*/

@include media-query(desk) {
    .product-list-heading {
        @include font-size(24px, 32px);
        font-family: $base-fallback-font;
        font-weight: $font-weight-bold;
        margin-top: 60px;
        margin-bottom: 18px;
        color: inherit;

        .wf-active & {
            font-family: $base-font;
            font-weight: $font-weight-medium;
        }
    }
}

.product-list-subheading {
    @include font-size(18px, 24px);
    font-family: $base-fallback-font;
    font-weight: $font-weight-bold;
    color: inherit;

    .wf-active & {
        font-family: $base-font;
        font-weight: $font-weight-medium;
    }
}

.product-list {
    margin-bottom: 4px;

    @include media-query(lap) {
        @include grid(20px);
    }
}

.product {
    display: flex;
    flex-direction: column;
    background-color: $colour-primary;
    color: $colour-ui-light;
    padding: 20px $base-spacing-unit;
    margin-bottom: 20px;
    position: relative;

    .product-list-related & {
        margin-left: 21px;
    }

    @include media-query(lap) {
        @include grid-item(1/2, 20px);
    }
}

.product-search {
    @include media-query(lap) {
        @include grid-item(1/1, 20px);
        flex-direction: row;
        padding: 0;

        .product-thumb {
            margin: 0;
            width: 50%;
        }
    }
}

.product-search-body {
    @include media-query(lap) {
        padding: 20px $base-spacing-unit;
        width: 50%;
    }
}

.product-search-smallprint {
    display: block;
    @include font-size(11px, 17px);

    > :last-child {
        margin-bottom: 0;
    }
}

.product-search-badge {
    position: absolute;
    left: 0;
    bottom: $base-spacing-unit;
    background-color: $colour-secondary;
    color: $colour-ui-light;
    padding: 4px $base-spacing-unit;
    font-weight: $font-weight-medium;
    border-radius: 0 4px 4px 0;
}

.product-thumb {
    display: block;
    margin: -20px (0 - $base-spacing-unit) 20px;
    background-color: $colour-tertiary;
    overflow: hidden;
    z-index: 1;

    &::before {
        content: "";
        display: block;
        padding-top: 53.666%;
    }

    &:hover  {
        .product-figure {
            transform: scale(1.05);
        }
    }
}

.product-figure {
    transition: opacity $base-anim-speed, transform $base-anim-speed * 1.5;
}

.product-header,
.product-title {
    color: $colour-ui-light
}

.product-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    min-height: 120px;
    background-size: 100% 120px;
    background-repeat: no-repeat;
    padding: 20px 44px 0 $base-spacing-unit;
    background-image: linear-gradient(to bottom, rgba($colour-tertiary, .60), rgba($colour-tertiary, .0));
}

.product-title {
    margin-bottom: 0;
}

.product-sleeps {
    @include font-size(18px);
    font-weight: $font-weight-medium;
}

.product-favourite {
    position: absolute;
    top: 0;
    right: 0;
    width: 44px;
    height: 44px;
    z-index: 2;
    transition: color $base-anim-speed;

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: $base-spacing-unit;
        height: $base-spacing-unit;
    }

    &:hover,
    &.is-favourite {
        color: $colour-secondary;
    }

    &:hover {
        .product-favourite-label {
            opacity: 1;
            transform: translate(5px, -50%);
        }
    }

    &.is-favourite {
        .product-star {
            opacity: 1;
        }
    }

    .product-search & {
        @include media-query(desk) {
            right: 50%;
        }
    }

    @include media-query(portable) {
        display: none;
    }
}

    .product-star {
        opacity: 0;
        transition: opacity $base-anim-speed;
    }

    .product-favourite-label {
        position: absolute;
        white-space: nowrap;
        left: 100%;
        background-color: $colour-secondary;
        color: $colour-ui-light;
        top: 50%;
        opacity: 0;
        padding: 15px 20px;
        transform: translate(0, -50%);
        transition: opacity $base-anim-speed, transform $base-anim-speed;
        pointer-events: none;

        &::before {
            content: "";
            position: absolute;
            right: 100%;
            top: 50%;
            border: 12px solid transparent;
            border-right-color: $colour-secondary;
            transform: translateY(-50%);
        }
    }

.product-price {
    @include font-size(16px);
    font-weight: $font-weight-medium;
    margin-bottom: 8px;

    .product-list-related & {
        text-align: center;
        margin-bottom: 20px;
    }
}

.product-description {
    p {
        margin-bottom: halve($base-spacing-unit);
    }
}

.product-action-list {
    margin-bottom: -10px;
    margin-top: auto;

    .product-list-related & {
        width: 100%;
        max-width: 224px;
        margin-left: auto;
        margin-right: auto;
    }
}

.product-action {
    margin-bottom: 10px;
}

.product-list-related {
    margin-left: -21px;
    margin-bottom: 0;

    .slick-list {
        overflow: visible;
    }

    .slick-track {
        margin-left: 0;
        margin-right: 0;
    }

    .slick-arrow {
        position: absolute;
        bottom: -7px;
        transition: opacity $base-anim-speed;

        .icon {
            width: 20px;
            height: 40px;
        }

        &.slick-disabled {
            pointer-events: none;
            opacity: 0.5;
        }

        @include media-query(desk) {
            bottom: calc(100% + #{$base-spacing-unit});
            z-index: 1;
        }
    }

    .slick-prev {
        left: 21px;

        @include media-query(desk) {
            right: 226px;
            left: auto;
        }
    }

    .slick-next {
        right: 0;
    }

    .slick-dots {
        @include media-query(desk) {
            position: absolute;
            bottom: calc(100% + 4px);
            right: 0;
            width: 246px;
        }
    }
}
