/*------------------------------------*\
    $ERROR
\*------------------------------------*/

.error {
    position: relative;
    box-shadow: inset 0 10px 10px rgba(#000, .1);
    padding-bottom: 72px;
}

.error-title {
    &#{&} {
        color: $colour-ui-light;
    }
}

.error-lede {
    @include font-size(16px);
    font-weight: $font-weight-medium;
}

.error-boat {
    position: absolute;
    bottom: 0;
    right: -22px;
    width: 237px;
    height: 59px;

    @include media-query(desk) {
        right: 20%;
    }
}
