/*------------------------------------*\
    $PRINT
\*------------------------------------*/

@media print {
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important; /* Black prints faster */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    .header,
    footer,
    .main-sidebar,
    .breadcrumbs,
    .breadcrumbs-btn,
    .btn-list,
    .accommodation-gallery,
    .related-products-wrapper,
    .accommodation-header-action:last-child,
    .favourites {
        display: none !important;
    }

    .accommodation-header-text,
    .accommodation-header-actions {
        position: static;
    }

    .accommodation-header-actions,
    .accommodation-desc {
        page-break-after: always;
    }

    .lazyload-figure {
        display: block !important;
        opacity: 1 !important;
        visibility: visible !important;
        transition: none !important;
    }
}
