/*------------------------------------*\
    $MODAL
\*------------------------------------*/

/**
 * Setup positioning and scrolling of modals
 */

.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    background-color: $colour-tertiary;
    transition: opacity $base-anim-speed linear, visibility 0s $base-anim-speed;
    opacity: 0;
    visibility: hidden;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    padding: 60px $base-spacing-unit $base-spacing-unit;

    &.is-active {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
        overflow: auto;
        overflow-x: hidden;
        backface-visibility: hidden;
        -webkit-overflow-scrolling: touch;
    }

    &::before {
        content: "";
        vertical-align: middle;
        line-height: 100%;
        height: 100%;
        display: inline-block;
        margin-left: -0.3em;
    }

    @include media-query(palm) {
        &,
        a:not([class]) {
            color: $colour-ui-light;
        }
    }

    @include media-query(lap) {
        padding: double($base-spacing-unit) $base-spacing-unit;
        background-color: rgba($colour-tertiary, .9);
    }
}


/**
 * Main body block styles
 */

.modal-body {
    position: relative;
    z-index: 1;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    white-space: normal;

    @include media-query(palm) {
        &::before {
            content: "";
            position: fixed;
            height: 65px;
            background-color: rgba($colour-tertiary, .75);
            left: 0;
            right: 0;
            top: 0;
            z-index: 1;
        }
    }

    @include media-query(lap) {
        background-color: $colour-ui-light;
    }
}


/**
 * Close button
 */

.modal-close {
    @include font-size(18px, 32px);
    position: fixed;
    left: $base-spacing-unit;
    color: $colour-ui-light;
    padding-left: 26px;
    font-weight: $font-weight-medium;
    z-index: 1;

    .icon {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-top: -1px;
    }

    @include media-query(palm) {
        transform: translateY(-50%);
        top: 32px;
    }

    @include media-query(lap) {
        position: absolute;
        bottom: 100%;
        left: 0;
        margin-bottom: 2px;
    }
}

.modal-content {
    display: none;
    width: calc(100vw - #{double($base-spacing-unit)});
    max-width: 770px;

    &.is-active {
        display: block;
    }

    @include media-query(lap) {
        padding: $base-spacing-unit $base-spacing-unit 1px;
    }
}

.modal-content-sml {
    max-width: 520px;

}

.modal-content-xs {
    max-width: 320px;
}

.modal-content-lrg {
    max-width: 1000px;
}

.modal-content-bleed {
    padding: 0;
}

.modal-title {
    @include media-query(palm) {
        &#{&} {
            color: $colour-ui-light;
        }
    }
}


.modal-contact {
    p:not(:last-child) {
        margin-bottom: halve($base-spacing-unit);
    }
}

.modal-freephone {
    @include font-size(18px);

    a {
        font-weight: $font-weight-bold;
    }
}

.modal-thumb {
    margin: (0 - $base-spacing-unit) (0 - $base-spacing-unit) $base-spacing-unit;
    display: block;

    &#{&}::before {
        padding-top: 50%;
    }
}

.modal-footer {
    margin: 0 (0 - $base-spacing-unit) -1px;
}

.modal-content-figure {
    padding: 0;
}

.modal-prev,
.modal-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    color: $colour-ui-light;
    width: 32px;
    height: 32px;
    transition: opacity $base-anim-speed, visibility 0s $base-anim-speed;

    &.is-active {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
    }

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.modal-prev {
    right: 100%;
}

.modal-next {
    left: 100%;
}
