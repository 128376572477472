/*------------------------------------*\
    $GALLERY
\*------------------------------------*/

.gallery-wrapper {
    @include media-query(portable) {
        margin-left: -38px;
        margin-right: -38px;
    }
}

.gallery {
    @include grid(3px);
    margin-bottom: $base-spacing-unit - 3px;
}

.gallery-item {
    @include grid-item(1/2, 3px);
    margin-bottom: 3px;

    &::before {
        content: "";
        display: block;
        padding-top: 42.35%;
    }
}
