/*------------------------------------*\
    $BUTTONS
\*------------------------------------*/

.btn {
    display: inline-block;
    vertical-align: middle;
    font: inherit;
    line-height: (20px/14px);
    text-align: center;
    cursor: pointer;
    margin: 0;
    padding: 13px $base-spacing-unit;
    border: 1px solid $colour-primary;
    min-width: 184px;
    background-color: $colour-primary;
    color: $colour-ui-light;
    transition: background-color $base-anim-speed, border-color $base-anim-speed, color $base-anim-speed;
    font-weight: $font-weight-bold;


    &,
    &:hover,
    &:focus {
        text-decoration: none;
    }

    &:hover {
        border-color: $colour-tertiary;
        background-color: $colour-tertiary;
        color: $colour-ui-light;
    }

    .wf-active & {
        font-weight: $font-weight-medium;
    }
}


/**
 * Colours
 */

.btn-secondary {
    border-color: $colour-secondary;
    background-color: $colour-secondary;


    @include media-query(palm) {
        .modal &:hover {
            border-color: $colour-ui-light;
            background-color: $colour-ui-light;
            color: $colour-tertiary;
        }
    }
}

.btn-ui {
    border-color: $colour-ui-light;
    background-color: $colour-ui-light;
    color: $colour-primary;

    &--invert {
        border-color: $colour-tertiary;
        background-color: $colour-tertiary;
        color: $colour-ui-light;

        &:hover {
            border-color: $colour-ui-light;
            background-color: $colour-ui-light;
            color: $colour-primary;
        }
    }
}

.btn-ui-ghost {
    border-color: $colour-ui-light;
    background-color: transparent;
}
.btn-ui-dark {
    background-color: $dark-colour-background;
    border: $dark-colour-background;
    color: $dark-colour-typography;
    &:hover {
        border-color: $colour-ui-light;
        background-color: $colour-ui-light;
        color: $colour-primary;
    }
}
.btn-tertiary-ghost {
    border-color: $colour-tertiary;
    background-color: transparent;
    color: $colour-tertiary;
}


/**
 * Block, full width buttons
 */

.btn-full {
    width: 100%;
    min-width: 0;
}

.btn-soft {
    border-radius: 4px;
}

.btn-list {
    > .grid-item {
        margin-bottom: halve($base-spacing-unit);
    }

    > :last-child {
        margin-bottom: $base-spacing-unit;
    }
}

.btn-sml {
    padding: 6px 8px;
}


/**
 * Submit buttons with spinners
 */

@keyframes btn-rotate {
    from {
        transform: translate(50%, -50%) rotate(0deg);
    }

    to {
        transform: translate(50%, -50%) rotate(360deg);
    }
}

.btn-ajax {
    position: relative;
    white-space: nowrap;
    transition: background-color $base-anim-speed, border-color $base-anim-speed, color $base-anim-speed, padding-right $base-anim-speed $base-anim-speed ease-in-out;

    &::after {
        content: "";
        border: 2px solid transparent;
        border-top-color: currentColor;
        border-bottom-color: currentColor;
        position: absolute;
        top: 50%;
        right: 23px;
        transform: translate(50%, -50%);
        width: 18px;
        height: 18px;
        border-radius: 99px;
        animation: btn-rotate $base-anim-speed * 2 linear infinite;
        transition: opacity $base-anim-speed;
        opacity: 0;
    }

    &.is-loading {
        padding-right: 58px;
        transition-delay: 0s;

        &::after {
            transition-delay: $base-anim-speed;
            opacity: 1;
        }
    }
}
