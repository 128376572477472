/*------------------------------------*\
    $INFO
\*------------------------------------*/

.info {
    display: table;
    width: 100%;
    margin-bottom: $base-spacing-unit;
}

.info-row {
    display: table-row;
}

.info-cell {
    display: table-cell;

    .info-med & {
        padding-bottom: halve($base-spacing-unit);
    }
}

.info-title {
    font-weight: $font-weight-medium;
}
