/*------------------------------------*\
    $FIGURE
\*------------------------------------*/

.figure {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $base-spacing-unit;
}

.figure-left {
    margin-left: 0;
}
