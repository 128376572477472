/*------------------------------------*\
    $BREADCRUMBS
\*------------------------------------*/

.breadcrumbs {
    border-bottom: 1px solid;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;
    color: $colour-secondary;
    justify-content: space-between;
    padding-bottom: 8px;

    @include media-query(portable) {
        margin-bottom:30px;

        .breadcrumbs-list + .breadcrumbs-list {
            display:none;
        }
    }

    @media (min-width: #{1280px + quadruple($base-spacing-unit)}) {
        margin-right: calc(1280px / 2 - 50vw + #{double($base-spacing-unit)});
    }

    @include dark-theme {
        color: $dark-colour-heading;
    }
}

.breadcrumbs-list {
    display: flex;
    margin-bottom: 0;

    @include media-query(portable) {
        flex-direction:column;
    }

    @include media-query(desk) {
        display: flex !important;
    }

    &:first-child {
        margin-right: $base-spacing-unit;

        @include media-query(portable) {
            flex:1;
            margin-right:0;
        }
    }
}

.breadcrumbs-item {
    &:not(:last-child) {
        &::after {
            content: "/";
            margin-left: 6px;
            margin-right: 6px;

            @include media-query(portable) {
                display:none;
            }
        }
    }
}

.breadcrumbs-link,
.breadcrumbs-btn {
    color: currentColor;
    transition: color $base-anim-speed;

    &:hover {
        color: $colour-tertiary;
    }
    @include dark-theme {
        color: $dark-colour-heading;
        &:hover {
            color: $colour-ui-light;
        }
    }
}

.breadcrumbs-link.is-current {
    font-weight: $font-weight-medium;
}

.breadcrumbs-btn {
    color: $colour-secondary;
    position: relative;
    padding-right: $base-spacing-unit;
    font-weight: $font-weight-medium;

    .icon {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }
}

.breadcrumbs-toggle {
    position: relative;
    width: 100%;

    .icon {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        transition: transform $base-anim-speed $anim-ease-in-out;
        width: 11px;
        height: 11px;
    }

    &.is-active .icon {
        transform: translateY(-50%) rotate(0.5turn);
    }

    @include media-query(desk) {
        display: none;
    }
}