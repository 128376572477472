/*------------------------------------*\
    $THUMB
\*------------------------------------*/

.thumb {
    display: block;
    overflow: hidden;
    margin-bottom: $base-spacing-unit;
    background-color: $colour-ui;

    &::before {
        content: "";
        display: block;
        padding-top: 56.25%;

    }

    &:hover  {
        .thumb-figure {
            transform: scale(1.05);
        }
    }
}

.thumb-figure {
    transition: opacity $base-anim-speed, transform $base-anim-speed * 1.5;
}
