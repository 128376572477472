/*------------------------------------*\
    $LANDMARK
\*------------------------------------*/

.landmark {
    margin-bottom: 40px - $base-spacing-unit;
    overflow: hidden;

    @include media-query(desk) {
        margin-bottom: 60px - $base-spacing-unit;
    }
}
