/*------------------------------------*\
    $ACCOMMODATION
\*------------------------------------*/

.accommodation-header {
    @include media-query(desk) {
        @include grid(0px);
        box-shadow: inset 0 0 10px rgba(#000, .1);
        min-height: calc(100vh - 268px);
        margin-bottom: 32px;
        position: relative;
    }
}

.accommodation-header-text {
    position: absolute;
    top: 22px;
    left: $base-spacing-unit;
    right: $base-spacing-unit;
    text-shadow: 0 3px 20px rgba(#000, .1);

    @include media-query(desk) {
        left: double($base-spacing-unit);
        right: double($base-spacing-unit);
    }
}

    .accommodation-title,
    .accommodation-subtitle {
        color: $colour-ui-light;
    }

    .accommodation-title {
        @include responsive-font(9.375vw, 30px, 40px);
        line-height: (48px / 30px);
        margin-bottom: 0;
    }

    .accommodation-subtitle {
        @include font-size(16px);
        font-weight: $font-weight-medium;

        @include media-query(desk) {
            @include font-size(18px);
        }
    }

.accommodation-header-gallery {
    position: relative;

    @include media-query(desk) {
        @include grid-item(2/3, 0px);
    }
}

.accommodation-header-info {
    padding: 30px 38px (30px - $base-spacing-unit);

    @include media-query(desk) {
        @include grid-item(1/3, 0px);
        padding: double($base-spacing-unit) double($base-spacing-unit) $base-spacing-unit;
        background-color: $colour-tertiary;
        color: $colour-ui-light;
        min-height: calc(100vh - 268px);
    }
}

.accommodation-gallery {
    position: relative;
    margin-bottom: 0;

    &,
    .slick-list,
    .slick-track {
        height: 100%;
    }

    .slick-arrow,
    .slick-dots {
        position: absolute;
    }

    @include media-query(portable) {
        height: calc(100vh - 112px);
    }

    @include media-query(desk) {
        .slick-dots {
            display: none !important;
        }
    }
}

.accommodation-gallery-slide {
    height: 100%;
    position: relative;
}

.accommodation-features-heading {
    @include font-size(16px);
    color: $colour-tertiary;

    .wf-active & {
        font-weight: $font-weight-medium;
        font-family: $base-font;
    }

    @include media-query(desk) {
        @include font-size(20px);
        color: $colour-ui-light;
    }
}

.accommodation-feature {
    position: relative;
    padding-left: 50px;

    .icon {
        position: absolute;
        top: 50%;
        left: 0;
        width: 30px;
        height: 30px;
        transform: translateY(-50%);

        @include media-query(portable) {
            color: $colour-primary;
        }
    }

    &:not(:last-child) {
        margin-bottom: $base-spacing-unit;
    }
}

.accommodation-feature-iconless {
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 4px;
        height: 4px;
        background-color: currentColor;
        border-radius: 100%;
        transform: translateY(-50%);
    }
}

.accommodation-features {
    > li {
        padding-left: 10px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0.9em;
            left: 0;
            width: 4px;
            height: 4px;
            border-radius: 100%;
            background-color: currentColor;
            transform: translateY(-50%);
        }

        @include media-query(desk) {
            &:not(:last-child) {
                margin-bottom: halve($base-spacing-unit);
            }
        }
    }
}

.accommodation-header-actions {
    position: absolute;
    margin-bottom: 0;
    bottom: 0;
    transform: translateY(50%);
    left: double($base-spacing-unit);
    min-width: calc(100% * 2/3 - #{quadruple($base-spacing-unit)});
    display: flex;

    @include media-query(portable) {
        display: none;
    }
}

    .accommodation-header-action {
        width: percentage(1/3);
    }

    .accommodation-header-btn {
        @include font-size(18px, 34px);
        display: block;
        padding: 14px;
        height:100%;
        background-color: $colour-primary;
        color: $colour-ui-light;
        font-weight: $font-weight-medium;
        text-align: center;
        transition: background-color $base-anim-speed;
        white-space: nowrap;

        :nth-child(2) > & {
            background-color: #01989B;
        }

        :nth-child(3) > & {
            background-color: $colour-secondary;
        }

        span {
            @include font-size(24px, 34px);
        }

        .icon {
            width: 34px;
            height: 34px;
            margin-left: 5px;
        }
    }

    a.accommodation-header-btn {
        &:hover {
            background-color: $colour-tertiary;
            color: $colour-ui-light;
        }
    }

.accommodation-book {
    margin-bottom: $base-spacing-unit;

    @include media-query(portable) {
        margin-top: -30px;
        margin-left: -38px;
        margin-right: -38px;
    }
}

.accommodation-book-title {
    @include font-size(16px);
    font-family: $base-fallback-font;
    color: $colour-ui-light;

    .wf-active & {
        font-weight: $font-weight-medium;
        font-family: $base-font;
    }
}

.accommodation-book-summary {
    @include font-size(18px);
}

    .accommodation-book-deposit {
        @include font-size(24px, 34px);
        display: block;
        font-weight: $font-weight-bold;
    }

.accommodation-book-action {
    &:not(:last-child) {
        margin-bottom: 10px;
    }
}

.accommodation-floorplan {
    @include media-query(desk) {
        margin-top: 60px;
        margin-bottom: 60px;
    }
}

.accommodation-tour {
    margin-bottom: $base-spacing-unit;

    @include media-query(portable) {
        margin-left: -38px;
        margin-right: -38px;
    }

    @include media-query(desk) {
        // margin-left: 0 - double($base-spacing-unit);
        margin-bottom: 30px;
    }

    // @media (min-width: #{1280px + quadruple($base-spacing-unit)}) {
    //     margin-left: calc(1280px / 2 - 50vw);
    // }
}
