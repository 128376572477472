/*------------------------------------*\
    $FAVOURITES
\*------------------------------------*/

.favourites {
    position: fixed;
    bottom: 0;
    transform: translateY(100%) translateY(-48px);
    right: 296px;
    z-index: 3;
    box-shadow: 0 0 10px rgba(#000, .1);
    width: 260px;
    transition: transform $base-anim-speed $anim-ease-in-out;

    &.is-disabled {
        transform: translateY(100%);
    }

    &.is-active {
        transform: none;
    }

    @include media-query(portable) {
        display: none;
    }
}

.favourites-toggle {
    position: relative;
    display: block;
    width: 100%;
    height: 48px;
    padding: 13px double($base-spacing-unit) 13px 20px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: $colour-tertiary;
    color: $colour-ui-light;
    font-weight: $font-weight-medium;
    box-shadow: 0 0 10px rgba(#000, .1);
    transition: transform $base-anim-speed ease-out;
}

.favourites-count {
    position: relative;;
    margin-left: halve($base-spacing-unit);
    padding-left: 21px;

    .icon {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
}

.favourites-chevron {
    position: absolute;
    right: $base-spacing-unit;
    top: 50%;
    transform: translate(50%, -50%) rotate(0.5turn);
    width: 14px;
    height: 14px;
    transition: transform $base-anim-speed $anim-ease-in-out;

    .is-active > & {
        transform: translate(50%, -50%);
    }
}


.favourites-body {
    padding: 20px;
    background-color: $colour-ui-light;
    // margin-bottom: 0;
}

.favourites-list {
    margin-bottom: 20px;
}

.favourites-item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(#000, .1);
        margin-bottom: 10px;
    }
}

.favourites-thumb {
    background-color: $colour-ui;
    width: percentage(1/3);
    margin-right: 10px;

    &::before {
        content: "";
        display: block;
        padding-top: 53.666%;
    }
}

.favourites-remove {
    @include font-size(13px, 1.5);
    display: block;
    color: $colour-secondary;

    &:hover {
        text-decoration: underline;
    }

    .comparison-row & {
        color: rgba($colour-ui-light, .5);

        &:hover {
            color: $colour-ui-light;
        }
    }
}

.favourites-heading {
    @include font-size(14px, 1.2);
    font-family: $base-font;
    font-weight: $font-weight-medium;
    margin-bottom: 0;
}
