/*------------------------------------*\
    $CATEGORIES
\*------------------------------------*/


.categories-title {
    @include font-size(20px, 32px);
    font-family: $base-fallback-font;
    margin-bottom: 8px;
    color: $colour-tertiary;
    font-weight: $font-weight-bold;

    .wf-active & {
        font-family: $base-font;
        font-weight: $font-weight-medium;
    }
}

.categories {
    margin-bottom: 30px;
}

.categories-link {
    display: block;
    border-bottom: 1px solid $colour-tertiary;
    padding: 7px 0;

    &.is-active {
        // @include font-size(16px);
        font-weight: $font-weight-medium;
    }
}
