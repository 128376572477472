/*------------------------------------*\
    $WIDGET
\*------------------------------------*/

.widget-grid {
    @include grid(0px);
}

    .widget-grid-item {
        @include grid-item(1/1, 0px);

        @include media-query(desk) {
            @include grid-width(1/3, 0px);
        }
    }

.widget {
    background-color: $colour-primary;
    color: $colour-ui-light;
    padding: 21px;
    margin-bottom: $base-spacing-unit;

    &::after {
        content: "";
        display: block;
        margin-bottom: -$base-spacing-unit;
    }

    @include media-query(portable) {
        margin-left: -38px;
        margin-right: -38px;
    }
}

.widget-secondary {
    background-color: $colour-secondary;
    @include dark-theme {
        background-color: $dark-colour-container;
    }
}

.widget-tertiary {
    background-color: $colour-tertiary;

    .widget-title,
    .link-bare:hover {
        color: $colour-ui-light;
    }

    .btn-ui-ghost:hover {
        border-color: $colour-ui-light;
        background-color: $colour-ui-light;
        color: $colour-primary;
    }

    .widget-thumb {
        background-color: shade($colour-tertiary, 80%);
    }
}

.widget-thumb {
    margin: -21px -21px 18px;
    display: block;
    overflow: hidden;
    background-color: $colour-tertiary;

    &::before {
        content: "";
        display: block;
        padding-top: 150/240 * 100%;
    }

    &:hover  {
        .widget-figure {
            transform: scale(1.05);
        }
    }
}

.widget-figure {
    transition: opacity $base-anim-speed, transform $base-anim-speed * 1.5;
}

.widget-title {
    @include font-size(22px);
    margin-bottom: 8px;
    color: $colour-ui-light;
}


.widget-trip-advisor {
    margin: 0 auto $base-spacing-unit;
    max-width: calc(100vw - #{double($base-spacing-unit)});
}

.widget-google-reviews {
    margin-bottom:$base-spacing-unit;
    border:1px solid $colour-tertiary;

    .widget-google-reviews_heading {
        margin:0; padding:($base-spacing-unit / 2) $base-spacing-unit;
        color:#FFF;
        background:$colour-primary;
        border-bottom:1px solid $colour-tertiary;
    }

    .review-item {
        padding: ($base-spacing-unit * 1.5) ($base-spacing-unit * 1.5) ($base-spacing-unit * 0.5);

        .review-item-meta {
            display:flex;
            flex-direction:row;
            flex-wrap:nowrap;
            align-items:center;
            padding-bottom:$base-spacing-unit / 2;

            img {
                flex-shrink:0;
                padding-right:16px;
                width:64px; height:auto;
            }

            .review-stars > ul {
                margin:0; padding:0;

                & > li {
                    display:inline;
                    line-height:1;
                }
            }
        }
    }
}

#google-reviews {
    position: relative;
    line-height: 1.4;
    min-height: 100px;

    .slick-arrow {
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        width: 32px;
        height: 32px;

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .slick-prev {
        left: 0;
    }

    .slick-next {
        right: 0;
    }
}

#CDSWIDSSP {
    width: 100% !important;
}
