/*------------------------------------*\
    $PROLOGUE
\*------------------------------------*/

.prologue-band {
    position: relative;
    padding-bottom: 80px - $base-spacing-unit;

    &.band-primary {
        @include media-query(desk) {
            padding-top: 60px;
            padding-bottom: 200px;
        }
    }

    @include media-query(desk) {
        padding-bottom: 138px;
    }
}

.prologue-band-sml {
    &,
    &.band-primary {
        padding-bottom: double($base-spacing-unit);
    }
}

.prologue {
    @include media-query(desk) {
        margin-top: 84px;
    }
}

.prologue-title {
    @include responsive-font(5vw, 32px, 58px);

    .band-primary & {
        color: $colour-ui-light;
    }

    @include media-query(portable) {
        margin-top: 60px - $base-spacing-unit;
    }

    @include media-query(desk) {
        text-align: right;
    }
}

.prologue-link {
    font-weight: $font-weight-bold;
    text-decoration: underline;
}

.prologue-boat,
.prologue-windmill {
    position: absolute;
    bottom: 0;

    .band-primary & {
        color: $colour-tertiary;
    }
}

.prologue-boat {
    width: 237px;
    height: 59px;
    right: -32px;

    @include media-query(desk) {
        left: 50%;
        transform: translateX(-50%);
        width: 261px;
        height: 65px;
    }
}

.prologue-windmill {
    width: 151px;
    height: 179px;
    right: 20%;
    transform: translateX(50%);

    @media screen and  (max-width: 1199px) {
        display: none;
    }
}
