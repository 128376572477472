/*------------------------------------*\
    $DRAWER
\*------------------------------------*/

.drawer-toggle {
    position: relative;
    width: 100%;
    padding-bottom: 7px;
    padding-top: 7px;
    border-bottom: 1px solid;
    margin-bottom: $base-spacing-unit;
    font-weight: $font-weight-medium;

    .icon {
        position: absolute;
        width: 12px;
        height: 12px;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }
}

.drawer {
    @include media-query(portable) {
        position: fixed;
        z-index: 5;
        left: 100%;
        top: 0;
        bottom: 0;
        background-color: $colour-ui-light;
        width: calc(100% - 64px);
        max-width: 320px;
        visibility: hidden;
        transition: transform $base-anim-speed $anim-ease-in, visibility 0s $base-anim-speed;
        padding: $base-spacing-unit $base-spacing-unit 1px;

        &.is-active {
            transition-timing-function: $anim-ease-out;
            transition-delay: 0s;
            transform: translateX(-100%);
            visibility: visible;
        }
    }
}

.drawer-title {
    @include font-size(16px);
    font-family: $base-fallback-font;
    color: $colour-tertiary;
    font-weight: $font-weight-bold;

    .wf-active & {
        font-family: $base-font;
        font-weight: $font-weight-medium;
    }
}

.drawer-close {
    position: fixed;
    top: 0;
    left: 0;
    width: 58px;
    height: 65px;
    color: $colour-ui-light;
    opacity: 0;
    visibility: hidden;
    transition: opacity $base-anim-speed, visibility 0s $base-anim-speed;
    z-index: 5;

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.is-active {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
    }
}

@include media-query(portable) {
    body {
        &::before {
            content: "";
            visibility: hidden;
            opacity: 0;
            background-color: $colour-tertiary;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 5;
            width: 100%;
            height: 100%;
            transition: opacity $base-anim-speed, visibility 0s $base-anim-speed;
        }

        &.has-active-drawer::before {
            opacity: 1;
            visibility: visible;
            transition-delay: 0s;
        }
    }
}
