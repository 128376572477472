/*------------------------------------*\
    $FRAGMENT
\*------------------------------------*/

/**
 * Hack for fragment identifiers taking sticky header into account
 *
 * NOTE: Fragment's must not have padding-top or border-top; in
 *       these instances a containing element should be used. May
 *       also cause unexpected results with elements that already
 *       have a ::before psuedo-element
 */

.fragment {
    &::before {
        content: " ";
        display: block;
        height: 66px;
        margin-top:  0 - (66px);
        visibility: hidden;
        pointer-events: none;

        @include media-query(nav) {
            height: 105px;
            margin-top: 0 - (163px);
        }
    }
}

.fragment-shift {
    &::before {
        height: 66px + $base-spacing-unit;
        margin-top: 0 - (66px + $base-spacing-unit);

        @include media-query(nav) {
            height: 163px + $base-spacing-unit;
            margin-top: 0 - (163px + $base-spacing-unit);
        }
    }
}
