/*------------------------------------*\
    $ACCESSIBILITY
\*------------------------------------*/

/**
 * Styles for screen-reader only elements
 */

.visuallyhidden {
    @include visuallyhidden;
}
