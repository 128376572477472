/*------------------------------------*\
    $BAND
\*------------------------------------*/

/**
 * Full width, coloured wrappers. Designed to be used around .containers
 */

.band {
    padding: 30px 38px (30px - $base-spacing-unit);

    @include media-query(desk) {
        padding: double($base-spacing-unit) double($base-spacing-unit) $base-spacing-unit;
    }

    &:not(.band-primary):not(.band-secondary):not(.band-tertiary) {
        & + & {
            padding-top: 0;
        }
    }
}


/**
 * Size modifiers
 */

.band-sml {
    @include media-query(portable) {
        padding: $base-spacing-unit $base-spacing-unit 1px;
    }

    @include media-query(desk) {
        padding-top: $base-spacing-unit;
        padding-bottom: 1px;
    }
}

.band-sml-top {
    @include media-query(desk) {
        padding-top: 30px;
    }
}

.band-horizontal {
    padding-top: 0;
    padding-bottom: 0;
}

.band-med {
    @include media-query(desk) {
        padding-top: 70px;
        padding-bottom: 70px - $base-spacing-unit;
    }
}


/**
 * Colour hierarchy
 */

.band-primary,
.band-secondary,
.vacancy,
.video,
.widget {
    background-color: $colour-primary;

    &,
    a:not([class]),
    h2:not([class]),
    h3:not([class]) {
        color: $colour-ui-light;
    }

    a:not([class]):hover {
        color: $colour-tertiary;
    }

    ::selection {
        background-color: $colour-ui-light;
        color: $colour-tertiary;
    }

    a, button, input, select, textarea {
        &:focus {
            outline-color: rgba($colour-ui-light, .8);
        }
    }
}

.band-secondary {
    background-color: $colour-secondary;
}

.band-tertiary {
    background-color: $colour-tertiary;

    &,
    a:not([class]) {
        color: $colour-ui-light;
    }

    a:not([class]):hover {
        color: $colour-primary;
    }
}
