/*------------------------------------*\
    $FONT-FACE
\*------------------------------------*/

@font-face {
    font-family: 'Hand of Sean';
    font-display: swap;
    src: url('../webfonts/HandofSean.woff2') format('woff2'),
         url('../webfonts/HandofSean.woff') format('woff');
}
