/*------------------------------------*\
    $PROPOSITION
\*------------------------------------*/

.proposition-gallery {
    background-color: $colour-tertiary;
}

.proposition-gallery,
.proposition-list {
    margin-bottom: 0
}

.proposition-figure {
    &::before {
        content: "";
        display: block;
        padding-top: 118%;

        @include media-query(desk) {
            padding-top: 40%;
        }
    }
}

.proposition-content {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    transform: translateY(-50%);

    > :last-child {
        margin-bottom: 0;
    }
}

.proposition-title {
    @include responsive-font(4.5vw, 48px, 64px);
    line-height: 1;
    // margin-bottom: 0;
    color: $colour-ui-light;
    text-shadow: 0 3px 20px rgba(#000, .4);

    .slick-initialized & {
        transition: opacity 500ms, transform $base-anim-speed;
        opacity: 0;
        transform: translateY(calc(-50% + 5px));
    }

    .slick-active & {
        opacity: 1;
        transform: translateY(-50%);
        transition-delay: 500ms;
    }
}


.proposition-wrapper {
    position: relative;
    background-color: $colour-ui;
}

.proposition-wrapper {
    .slick-arrow {
        position: absolute;
        top: 50%;
        z-index: 1;
        transform: translateY(-50%);
        width: double($base-spacing-unit);
        height: double($base-spacing-unit);

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            height: 40px;
            transform: translate(-50%, -50%);
            transition: transform $base-anim-speed ease-in-out;
        }
    }

    .slick-prev {
        left: 8px;

        &:hover {
            .icon {
                transform: translate(calc(-50% - 5px), -50%);
            }
        }

        @media screen and (min-width: 1300px) {
            left: 25px;
        }
    }

    .slick-next {
        right: 8px;

        &:hover {
            .icon {
                transform: translate(calc(-50% + 5px), -50%);
            }
        }

        @media screen and (min-width: 1300px) {
            right: 25px;
        }
    }
}

.proposition {
    position: relative;
    text-align: center;
    padding: 20px 64px 1px;
    transition: background-color $base-anim-speed, color $base-anim-speed;

    &.slick-current {
        background-color: $colour-primary;
        color: $colour-ui-light;
    }
}

.proposition-heading {
    @include font-size(25px, 36px);
    margin-bottom: 8px;
    font-weight: $font-weight-bold;
    font-family: $base-fallback-font;

    .wf-active & {
        font-family: $base-font;
    }

    .slick-current & {
        color: $colour-ui-light;
    }
}

.proposition-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
