/*------------------------------------*\
    $OFFER
\*------------------------------------*/

.offer-wrapper {
    position: relative;
}

.offer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;
    z-index: 1;
    padding: halve($base-spacing-unit) $base-spacing-unit;
    background-color: rgba($colour-tertiary, .7);
    text-align:center;

    &,
    a,
    button {
        color: $colour-ui-light;
    }

    a,
    button {
        font-weight: $font-weight-medium;
        text-decoration: none !important;

        &:hover {
            text-decoration: underline;
        }
    }

    > :last-child {
        margin-bottom: 0;
    }
}
