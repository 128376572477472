/*------------------------------------*\
    $IMG-HIDE
\*------------------------------------*/

/**
 * Visually hide images whilst preserving intrinsic width/height for
 * improved page-speed scores
 */

.img-hide {
    @include img-hide
}
