/*------------------------------------*\
    $LAZYLOAD
\*------------------------------------*/

.lazyload-wrapper {
    position: relative;
}

.lazyload-figure {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    transition: opacity $base-anim-speed;

    &.lazyloaded {
        opacity: 1;
    }
}
