/*------------------------------------*\
    $MAP
\*------------------------------------*/

.map {
    position: relative;
    background-color: $colour-ui;
    margin-bottom: $base-spacing-unit;

    &::before {
        content: "";
        display: block;
        height: 300px;

        @include media-query(desk) {
            padding-top: 43%;
        }
    }
}

.map-element {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.map-wysiwyg {
    position: relative;
    background-color: $colour-ui;
    margin-bottom: $base-spacing-unit;

    &::before {
        content: "";
        display: block;
        padding-top: 75%;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
