/*------------------------------------*\
    $PRICE
\*------------------------------------*/

.price {
    margin-bottom: $base-spacing-unit;
}

.price-item {
    &:not(:last-child) {
        padding-bottom: halve($base-spacing-unit);
        border-bottom: 1px solid;
        margin-bottom: halve($base-spacing-unit);
    }
}

.price-total {
    @include font-size(18px);
    font-weight: $font-weight-medium;
}
