/*------------------------------------*\
    $COMPARISON
\*------------------------------------*/

.comparison {

}

.comparison-row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.comparison-heading {
    @include font-size(20px);
    padding: $base-spacing-unit 30px 25px;
    font-weight: $font-weight-medium;
    background-color: $colour-primary;
    color: $colour-ui-light;
    width: 25%;
    margin-bottom: 2px;
}

.comparison-item-list {
    width: 75%;
    display: flex;
    margin-left: -2px;
}

.comparison-thumb {
    display: block;
    overflow: hidden;
    margin: -21px -21px 21px;

    &::before {
        content: "";
        display: block;
        padding-top: 56.25%;
    }

    &:hover {
        .comparison-figure {
            transform: scale(1.05);
        }
    }
}

.comparison-figure.lazyload-figure {
    transition: opacity $base-anim-speed, transform $base-anim-speed * 1.5;
}

.comparison-item {
    @include font-size(16px);
    flex: 1 0 0%;
    background-color: $colour-tertiary;
    color: $colour-ui-light;
    padding: 21px;
    margin-left: 2px;
    margin-bottom: 2px;
    min-height: 72px;
    max-width: 50%;

    > :last-child {
        margin-bottom: 0;
    }

    .btn-secondary:hover {
        border-color: $colour-ui-light;
        background-color: $colour-ui-light;
        color: $colour-tertiary;
    }
}

.comparison-item-dimensions {
    padding-top: 11px;
    padding-bottom: 12px;
}

.comparison-item-header {
    background-color: $colour-primary;
}

.comparison-price {
    span {
        @include font-size(26px, 24px);
        font-weight: $font-weight-bold;
    }
}

.comparison-feature {
    @include font-size(14px);
    position: relative;
    padding-left: 50px;

    .icon {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
    }

    &:not(:last-child) {
        margin-bottom: halve($base-spacing-unit);
    }
}

.comparison-item-floorplan {
    background-color: transparent;
}

.comparison-title {
    @include font-size(20px);
    margin-bottom: 0;
    color: $colour-ui-light;
}
