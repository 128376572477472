/*------------------------------------*\
    $VIDEO
\*------------------------------------*/

.video-wrapper {
    margin-bottom: $base-spacing-unit;

    @include media-query(desk) {
        @include grid(0px);
    }
}

.video {
    &::before {
        content: "";
        padding-top: 56.25%;
        display: block;
    }

    @include media-query(desk) {
        @include grid-item(7/10, 0px);

        &.video-full {
            background-color: $colour-ui;
            @include grid-item(1/1, 0px);
        }
    }
}

.video-description {
    background-color: $colour-primary;
    color: $colour-ui-light;
    padding: 20px;

    > :last-child {
        margin-bottom: 0;
    }

    @include media-query(desk) {
        @include grid-item(3/10, 0px);
    }
}
