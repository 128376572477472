/*------------------------------------*\
    $HEADINGS
\*------------------------------------*/

/**
 * Dual-stranded trump heading styles, copied from
 * `../base/_headings.scss`
 */

.alpha, .beta, .gamma, .delta, .epsilon, .zeta {
    @include heading;
}

.alpha {
    @include heading-1;
}

.beta {
    @include heading-2;
    @include dark-theme {
        color: $dark-colour-heading;
    }
}
.main-content, 
.main-sidebar {
    @include dark-theme {
        h1, h2, h3, h4, h5, h6 {
            font-family: "objektiv-mk1", sans-serif;
        }
    }
}

.gamma {
    @include heading-3;
}

.delta {
    @include heading-4;
}

.epsilon {
    @include heading-5;
}

.zeta {
    @include heading-6;
}
