/*------------------------------------*\
    $HEADINGS
\*------------------------------------*/

// Predefine heading mixins which can be reused in trumps layer later

@mixin heading {
    font-family: $font-primary;
    font-weight: $font-weight-normal;
    color: $colour-primary;
}

@mixin heading-1 {
    @include font-size(64px, 1);
}

@mixin heading-2 {
    @include responsive-font(4.5vw, 30px, 44px);
    line-height: 1.6;
}

@mixin heading-3 {
    @include font-size(25px, 1.44);
}

@mixin heading-4 {
    @include font-size(18px, 21px);
}

@mixin heading-5 {
    @include font-size($base-font-size);
}

@mixin heading-6 {
    @include font-size($base-font-size);
}


/**
 * Declare base heading sizes and styles
 */

h1, h2, h3, h4, h5, h6 {
    @include heading;
}

h1 {
    @include heading-1;
}

h2 {
    @include heading-2;
}

h3 {
    @include heading-3;
}

h4 {
    @include heading-4;
}

h5 {
    @include heading-5;
}

h6 {
    @include heading-6;
}
