/*------------------------------------*\
    $VACANCY
\*------------------------------------*/

.vacancy {
    display: flex;
    flex-direction: column;
    padding: $base-spacing-unit $base-spacing-unit 1px;

    @include media-query(portable) {
        &:nth-of-type(even) {
            background-color: $colour-tertiary;

            ::selection {
                background-color: $colour-primary;
                color: $colour-ui-light;
            }

            .btn-secondary:hover {
                border-color: $colour-ui-light;
                background-color: $colour-ui-light;
                color: $colour-tertiary;
            }
        }

        &:last-child {
            margin-bottom: $base-spacing-unit;
        }
    }

    @include media-query(desk) {
        @include font-size(16px);
        padding: $base-spacing-unit 32px 32px;
        margin-bottom: 21px;
    }
}

.vacancy-title {
    color: $colour-ui-light;
}

.vacancy-footer {
    @include media-query(desk) {
        margin: auto auto 0;
        max-width: 280px;
        width: 100%;
    }
}
