/*------------------------------------*\
    $JQUERY-UI
\*------------------------------------*/

/* jQuery UI - v1.12.1 - 2018-07-10
 * http://jqueryui.com
 * Includes: core.css, datepicker.css
 * Copyright jQuery Foundation and other contributors; Licensed MIT */

/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
	display: none;
}
.ui-helper-hidden-accessible {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
.ui-helper-reset {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	line-height: 1.3;
	text-decoration: none;
	font-size: 100%;
	list-style: none;
}
.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
	content: "";
	display: table;
	border-collapse: collapse;
}
.ui-helper-clearfix:after {
	clear: both;
}
.ui-helper-zfix {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	opacity: 0;
}

.ui-front {
	z-index: 100;
}


/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
	cursor: default !important;
	pointer-events: none;
	opacity: 0.5;
}


/* Icons
----------------------------------*/
.ui-icon {
	display: inline-block;
	vertical-align: middle;
	margin-top: -.25em;
	position: relative;
	text-indent: -99999px;
	overflow: hidden;
	background-repeat: no-repeat;
}

.ui-widget-icon-block {
	left: 50%;
	margin-left: -8px;
	display: block;
}

/* Misc visuals
----------------------------------*/

/* Overlays */
.ui-widget-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.ui-datepicker {
	width: 17em;
	padding: .2em .2em 0;
	display: none;


    margin-top: 6px;
    background-color: rgba($colour-tertiary, .88);
    color: $colour-ui-light;
    box-shadow: 0 0 10px rgba(#000, .2);

    &::before {
        content: "";
        display: block;
        position: absolute;
        left: $base-spacing-unit;
        transform: translateX(-50%);
        border: 6px solid transparent;
        border-bottom-color: rgba($colour-tertiary, .88);
        bottom: 100%;
    }
}
.ui-datepicker-header {
	position: relative;
	padding: .2em 0;
}
.ui-datepicker-prev,
.ui-datepicker-next {
	position: absolute;
	top: 2px;
	width: 1.8em;
	height: 1.8em;
    color: $colour-ui-light;
    transition: background-color $base-anim-speed;
    cursor: pointer;

    &:hover {
        background-color: rgba($colour-primary, .5);
        color: $colour-ui-light;

        .ui-datepicker-inline & {
            background-color: $colour-tertiary;
        }
    }

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 6px solid transparent;
    }
}

.ui-datepicker .ui-datepicker-prev {
    &::before {
        border-right-color: currentColor;
        border-left-width: 0;
    }
}

.ui-datepicker .ui-datepicker-next {
    &::before {
        border-left-color: currentColor;
        border-right-width: 0;
    }
}
// .ui-datepicker .ui-datepicker-prev-hover,
// .ui-datepicker .ui-datepicker-next-hover {
// 	top: 1px;
// }
.ui-datepicker .ui-datepicker-prev {
	left: 2px;
}
.ui-datepicker .ui-datepicker-next {
	right: 2px;
}
// .ui-datepicker .ui-datepicker-prev-hover {
// 	left: 1px;
// }
// .ui-datepicker .ui-datepicker-next-hover {
// 	right: 1px;
// }
.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
	display: block;
	position: absolute;
	left: 50%;
	margin-left: -8px;
	top: 50%;
	margin-top: -8px;
}

.ui-datepicker .ui-datepicker-title {
	margin: 0 2.3em;
	line-height: 1.8em;
	text-align: center;
}
.ui-datepicker .ui-datepicker-title select {
	font-size: 1em;
	margin: 1px 0;
}
.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
	width: 45%;
}
.ui-datepicker table {
	width: 100%;
	font-size: .9em;
	border-collapse: collapse;
	margin: 0 0 .4em;
	table-layout: fixed;
}
.ui-datepicker th {
	padding: .7em .3em;
	text-align: center;
	font-weight: bold;
	border: 0;

    // color: $colour-ui-light;
}
.ui-datepicker td {
	border: 0;
	padding: 1px;
}
.ui-datepicker td span,
.ui-datepicker td a,
.ui-datepicker td button {
    width: 100%;
	display: block;
	padding: .2em;
	line-height: inherit;
	text-align: center;
	text-decoration: none;
    border-radius: 3px;

    color: currentColor;
    transition: background-color $base-anim-speed, color $base-anim-speed;

    &:hover {
        background-color: rgba($colour-primary, .5);
		color: $colour-ui-light;
    }

	&.ui-state-active {
		background-color: $colour-ui-light;
		color: $colour-tertiary;
		font-weight: $font-weight-bold;
	}

    &.calendar-hovered {
        background-color: rgba($colour-selected, .2);
    }

	&.calendar-bookable {
		background-color: rgba(149,255,1,0.5);
	}

    &.calendar-selected {
        background-color: $colour-selected !important;
        color: $colour-tertiary !important;
        font-weight: $font-weight-bold;
    }

    &.calendar-selected-first,
    &.calendar-selected-last {
        background: repeating-linear-gradient(-45deg, transparent, transparent 25%, rgba($colour-primary, .25) 25%, rgba($colour-primary, .25) 50%, transparent 50%) top left;
        background-size: 30px 30px;
        // background-image: linear-gradient(to right bottom, rgba($colour-selected, 0) 49%, $colour-selected 50%);
        // background-image: linear-gradient(to left top, rgba($colour-selected, 0) 49%, $colour-selected 50%);
    }
}
    .ui-datepicker-inline td a,
    .ui-datepicker-inline td button {
		&:hover {
	        background-color: $colour-tertiary;
			color: $colour-ui-light;
		}

		&.ui-state-active {
			background-color: $colour-ui-light;
			color: $colour-tertiary;
			font-weight: $font-weight-bold;
		}
    }


.ui-datepicker .ui-datepicker-buttonpane {
	background-image: none;
	margin: .7em 0 0 0;
	padding: 0 .2em;
	border-left: 0;
	border-right: 0;
	border-bottom: 0;
}
.ui-datepicker .ui-datepicker-buttonpane button {
	float: right;
	margin: .5em .2em .4em;
	cursor: pointer;
	padding: .2em .6em .3em .6em;
	width: auto;
	overflow: visible;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
	float: left;
}

/* with multiple calendars */
.ui-datepicker.ui-datepicker-multi {
	width: auto;
}
.ui-datepicker-multi .ui-datepicker-group {
	float: left;
}
.ui-datepicker-multi .ui-datepicker-group table {
	width: 95%;
	margin: 0 auto .4em;
}
.ui-datepicker-multi-2 .ui-datepicker-group {
	width: 50%;
}
.ui-datepicker-multi-3 .ui-datepicker-group {
	width: 33.3%;
}
.ui-datepicker-multi-4 .ui-datepicker-group {
	width: 25%;
}
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
	border-left-width: 0;
}
.ui-datepicker-multi .ui-datepicker-buttonpane {
	clear: left;
}
.ui-datepicker-row-break {
	clear: both;
	width: 100%;
	font-size: 0;
}

/* RTL support */
.ui-datepicker-rtl {
	direction: rtl;
}
.ui-datepicker-rtl .ui-datepicker-prev {
	right: 2px;
	left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next {
	left: 2px;
	right: auto;
}
.ui-datepicker-rtl .ui-datepicker-prev:hover {
	right: 1px;
	left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next:hover {
	left: 1px;
	right: auto;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane {
	clear: right;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button {
	float: left;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current,
.ui-datepicker-rtl .ui-datepicker-group {
	float: right;
}
.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
	border-right-width: 0;
	border-left-width: 1px;
}

/* Icons */
.ui-datepicker .ui-icon {
	display: block;
	text-indent: -99999px;
	overflow: hidden;
	background-repeat: no-repeat;
	left: .5em;
	top: .3em;
}

/**
 * Static Datepickers
 */

.ui-datepicker-inline {
    margin: halve($base-spacing-unit) auto $base-spacing-unit;
    border: 1px solid;
    border-radius: 3px;
    background-color: transparent;
    width: 100%;
    max-width: 300px;
    box-shadow: none;

    &::before {
        display: none;
    }
}
