.widget-list {
    @include dark-theme {
        label {
            color: $dark-colour-typography;
            a {
                color: $dark-colour-heading;
                text-decoration: underline;
            }
        }
        .field-optional::before {
            color: $dark-colour-heading;
        }
        .field-control {
            background-color: $dark-colour-container;
            color: $dark-colour-typography;
            border-color: $dark-color-border;
        }
    }
}