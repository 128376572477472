/*------------------------------------*\
    $SPLIT
\*------------------------------------*/

.split {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.split-title {
    flex-grow: 1;
}

.split-top {
    align-items: flex-start;
}
