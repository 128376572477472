/*------------------------------------*\
    $BROCHURE
\*------------------------------------*/

.brochure {
    &::before {
        content: "";
        display: block;
        padding-top: 61.4%;
    }
}
