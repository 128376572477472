/*------------------------------------*\
    $BACK
\*------------------------------------*/

.back {
    transition: color $base-anim-speed;

    .icon {
        margin-top: -2px;
    }

    @include media-query(desk) {
        &:hover {
            color: $colour-primary;
        }

        .icon {
            width: 20px;
            height: 39px;
            margin-right: 10px;
        }
    }

    @include media-query(portable) {
        color: $colour-secondary;
        position: relative;
        padding-right: $base-spacing-unit;
        font-weight: $font-weight-medium;

        &:hover {
            color: $colour-tertiary;
        }
    }
}
