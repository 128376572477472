/*------------------------------------*\
    $BOOKING
\*------------------------------------*/

.booking {
    @include media-query(nav) {
        padding: 7px $base-spacing-unit 12px;
    }
}

.booking-close,
.booking-heading {
    @include media-query(nav) {
        display: none;
    }
}

.booking-close-wrapper {
    position: fixed;
    top: 0;
    right: 100%;
    transition: transform $base-anim-speed;
    z-index: 4;
    transition: transform $base-anim-speed $anim-ease-in;
    width: 100%;
    padding: 17px 14px;
    max-width: 320px;
    background-color: rgba($colour-tertiary, .8);

    .is-active > & {
        transform: translateX(100%);
        transition-timing-function: $anim-ease-out;
    }
}

.booking-close {
    @include font-size(18px, 32px);
    padding-left: 35px;
    z-index: 4;
    font-weight: $font-weight-medium;
    position: relative;

    .icon {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translate(-50%, -50%);
        width: 15px;
        height: 15px;
    }

    @include media-query(nav) {
        display: none;
    }
}

.booking-heading {
    @include font-size(16px);
    color: $colour-ui-light;
    font-family: $base-font;
    font-weight: $font-weight-medium;
}

.booking-container {
    max-width: 1400px;

    @include media-query(nav-portable) {
        position: absolute;
        top: 0;
        right: 100%;
        width: 100%;
        max-width: 320px;
        height: 100vh;
        padding: 74px 21px;
        z-index: 3;
        background-color: $colour-tertiary;
        outline: none;
        overflow: auto;
        transition: transform $base-anim-speed $anim-ease-in, box-shadow $base-anim-speed;

        .is-active > & {
            transform: translateX(100%);
            transition-timing-function: $anim-ease-out;
            box-shadow: 0 0 10px rgba(#000, .1);
        }

        // &::before {
        //     content: "";
        //     position: absolute;
        //     height: 66px;
        //     left: 0;
        //     top: 0;
        //     width: 100%;
        //     background-color: rgba($colour-tertiary, .75);
        // }
    }
}

.booking-field {
    &#{&} {
        @include media-query(nav-portable) {
            margin-bottom: halve($base-spacing-unit);

            &:last-child {
                margin-bottom: $base-spacing-unit;
            }
        }

        @include media-query(nav) {
            margin-bottom: 0;
        }
    }

    .field-control {
        // padding-left: 0;
        padding-right: 0;
        background-color: transparent;
        color: $colour-ui-light;
        border-bottom-color: $colour-ui-light !important;
        border-radius: 0;
    }

    .is-invalid .field-control {
        color: $colour-ui-light;
    }

    .field-select {
        .field-control {
            padding-right: 18px;
        }

        &::after {
            border-color: $colour-ui-light;
            right: 2px;
            transform: translateY(-50%) rotate(45deg);
        }
    }

    .field-datepicker {
        .field-control {
            padding-right: 18px;
        }

        .icon {
            color: $colour-ui-light;
            right: 0;
            transform: translateY(-50%);
        }
    }
}

.booking-btn {
    @include media-query(nav-portable) {
        &#{&} {
            border-color: $colour-secondary;
            background-color: $colour-secondary;
            color: $colour-ui-light;

            &:hover {
                border-color: $colour-ui-light;
                background-color: $colour-ui-light;
                color: $colour-tertiary;
            }
        }
    }

    @include media-query(nav) {
        &#{&} {
            margin-top: 30px;
        }
    }
}
