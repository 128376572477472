/*------------------------------------*\
    $NEWSLETTER
\*------------------------------------*/

.newsletter {
    position: relative;
}

.newsletter-boat {
    position: absolute;
    bottom: 0;
    right: 7%;
    width: 336px;
    height: 83px;

    @media screen and (max-width: 1099px) {
        display: none;
    }
}
