/*------------------------------------*\
    $SOCIAL
\*------------------------------------*/

.social {
    display: block;
    position: relative;
    margin-bottom: $base-spacing-unit;
    color: $colour-primary;
    width: $base-spacing-unit;
    height: $base-spacing-unit;

    &::before {
        content: "";
        position: absolute;
        top:  50%;
        left: 50%;
        width: 36px;
        height: 36px;
        transform: translate(-50%, -50%);
        border-radius: 99px;
        transition: background-color $base-anim-speed;
    }

    &:hover {
        color: $colour-ui-light;

        &::before {
            background-color: $colour-tertiary;
        }
    }

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .band-tertiary & {
        color: $colour-ui-light;

        &:hover {
            color: $colour-tertiary;

            &::before {
                background-color: $colour-ui-light;
            }
        }
    }
}

.social-lrg {
    @include media-query(lap) {
        width: 32px;
        height: 32px;

        &::before {
            width: 48px;
            height: 48px;
        }

        .icon {
            width: 24px;
            height: 24px;
        }
    }
}
