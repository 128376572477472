/*------------------------------------*\
    $ICON
\*------------------------------------*/

/**
 * Icon object - used with <svg> icons
 */

.icon {
    fill: currentColor;
    vertical-align: middle;
    display: inline-block;
    width: 18px;
    height: 18px;
}
