/*!
 * Architecture here based on inuitcss, by @csswizardry
 * github.com/inuitcss | inuitcss.com
 */
//------------------------------------*\
//  $SETTINGS
//------------------------------------*/
// Global variables, config switches
@import "settings/base", "settings/breakpoints";

//------------------------------------*\
//  $TOOLS
//------------------------------------*/
// Default mixins and functions
@import "tools/functions", "tools/mixins", "tools/responsive";

//------------------------------------*\
//  $GENERIC
//------------------------------------*/
// Ground-zero styles (Normalize.css, resets, box-sizing)
@import "generic/font-face", "generic/resetalize", "generic/shared";

//------------------------------------*\
//  $BASE
//------------------------------------*/
// Unclassed HTML element selectors
@import "base/page", "base/headings", "base/images", "base/links", "base/dark-theme";

//------------------------------------*\
//  $OBJECTS
//------------------------------------*/
// Cosmetic-free design patterns
@import "objects/bleed", "objects/container", "objects/figure", "objects/grid",
    "objects/icon", "objects/link-bare", "objects/split";

//------------------------------------*\
//  $COMPONENTS
//------------------------------------*/
// Designed components, chunks of UI
@import "components/accordion", "components/accommodation", "components/ajax",
    "components/alert", "components/back", "components/band",
    "components/booking", "components/breadcrumbs", "components/brochure",
    "components/btn", "components/categories", "components/check",
    "components/comparison", "components/countdown", "components/drawer",
    "components/error", "components/facilities", "components/favourites",
    "components/field", "components/footer", "components/fragment",
    "components/gallery", "components/header", "components/hero",
    "components/illustrated-map", "components/info", "components/key",
    "components/landmark", "components/lazyload", "components/lede",
    "components/lock", "components/newsletter", "components/page",
    "components/pagination", "components/post", "components/product",
    "components/prologue", "components/proposition", "components/price",
    "components/main", "components/map", "components/modal", "components/nav",
    "components/offer", "components/recaptcha", "components/results",
    "components/search", "components/slick", "components/slider",
    "components/social", "components/subheading", "components/subnav",
    "components/team", "components/thumb", "components/tour",
    "components/typography", "components/upload", "components/vacancy",
    "components/video", "components/wait", "components/widget",
    "components/zopim", "components/jquery-ui", "components/filters";

//------------------------------------*\
//  $TRUMPS
//------------------------------------*/
// Helpers, utilities and overrides
@import "trumps/accessibility", "trumps/align", "trumps/clearfix",
    "trumps/headings", "trumps/hide", "trumps/img-hide", "trumps/push",
    "trumps/print", "trumps/widths";

// Please do not add any styles to the bottom of this file - add
// edits to the relevant partial or create a new partial
