/*------------------------------------*\
    $SEARCH
\*------------------------------------*/

.search {
    position: absolute;

    @include media-query(nav-portable) {
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: -1;
        background-color: rgba($colour-tertiary,.7);
        color: $colour-ui-light;
        transition: transform $base-anim-speed $anim-ease-in;

        &.is-active {
            transform: translateY(100%);
            transition-timing-function: $anim-ease-out;
        }
    }

    @include media-query(nav) {
        // background-color: $colour-ui-light;
        top: 43px;
        right: 10px;
        left: 245px;
        opacity: 0;
        z-index: 3;
        visibility: hidden;
        transition: opacity $base-anim-speed, visibility 0s $base-anim-speed;

        &.is-active {
            opacity: 1;
            visibility: visible;
            transition-delay: 0s;
        }

        .header-sticky & {
            z-index: 3;
            right: 74px;
            top: 9px;
        }

        @media (min-width: 1040px) {
            left: 314px;
        }
    }
}

.search-toggle {
    position: relative;
    transition: color $base-anim-speed;

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .header-sticky & {
        width: 32px;
        height: 32px;
        overflow: hidden;
        text-indent: 100%;
        margin-left: 26px;
        position: relative;
        z-index: 2;

        .has-active-nav & {
            color: $colour-ui-light;
        }
    }

    @include media-query(nav-portable) {
        width: 32px;
        height: 32px;
        overflow: hidden;
        text-indent: 100%;
    }

    @include media-query(nav) {
        .header:not(.header-sticky) & {
            padding-right: 30px;
            font-weight: $font-weight-light;

            .icon {
                left: auto;
                right: 0;
                transform: translateY(-50%);
            }
        }

        &:hover {
            color: $colour-primary;
        }
    }
}

.search-control {
    @include font-size(18px);
    padding-top: 16px;
    padding-bottom: 16px;

    @include media-query(nav-portable) {
        border: none;
        background-color: transparent;
        color: $colour-ui-light;

        &:focus::placeholder {
            opacity: 1;
        }
    }

    @include media-query(nav) {
        background-color: $colour-ui-light;
        padding-top: 15px;
        padding-bottom: 15px;

        .header-sticky & {
            @include font-size(16px);
            padding-top: 11px;
            padding-bottom: 11px;
        }
    }
}

.search-close {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 56px;

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 16px;
        height: 16px;
    }
}
