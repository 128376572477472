/*------------------------------------*\
    $SUBNAV
\*------------------------------------*/

.subnav {
    position: relative;
    z-index: 2;

    .nav-secondary & {
        z-index: 3;
    }

    @include media-query(nav-portable) {
        padding-right: 24px;
    }

    @include media-query(nav) {
        &:hover {
            .subnav-list {
                .header:not(.header-sticky) & {
                    opacity: 1;
                    visibility: visible;
                    transition-delay: 0s;
                    transform: translateX(-50%);
                }
            }
        }
    }
}

.subnav-toggle {
    color: $colour-ui-light;
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 12px;
        height: 12px;
        transform: translate(-50%, -50%);
        transition: transform $base-anim-speed;
    }

    &.is-active {
        .icon {
            transform: translate(-50%, -50%) rotate(0.5turn);
        }
    }

    @include media-query(nav) {
        display: none;
    }
}

.subnav-list {
    margin-bottom: 0;

    @include media-query(nav-portable) {
        display: none;
        margin-left: 10px;

        &::before {
            content: "";
            display: block;
            height: halve($base-spacing-unit);
        }
    }

    @include media-query(nav) {
        display: block !important;
        position: absolute;
        top: 100%;
        left: 50%;
        min-width: 100%;
        visibility: hidden;
        opacity: 0;
        transition: opacity $base-anim-speed, transform $base-anim-speed $anim-ease-out, visibility 0s $base-anim-speed;

        .header:not(.header-sticky) & {
            transform: translate(-50%, 6px);
            background-color: rgba($colour-tertiary, .9);
            box-shadow: 0 0 10px rgba(#000, .2);
            padding-top: 6px;
            padding-bottom: 6px;

            &::before {
                content: "";
                position: absolute;
                bottom: 100%;
                left: 50%;
                transform: translateX(-50%);
                border: 6px solid transparent;
                border-bottom-color: rgba($colour-tertiary, .9);
            }
        }

        .header-sticky & {
            margin-top: 16px;
            position: static;
            opacity: 1;
            transition: visibility 0s $base-anim-speed;

            .has-active-nav & {
                visibility: visible;
                transition-delay: 0s;
            }
        }
    }
}

.subnav-item {
    @include media-query(nav-portable) {
        &:not(:last-child) {
            margin-bottom: halve($base-spacing-unit);
        }
    }
}

.subnav-link {
    color: $colour-ui-light;
    font-weight: $font-weight-light;

    &.is-current {
        text-decoration: underline;
    }

    .header-sticky & {
        @include font-size(16px, 24px);
        padding: 5px 0;
        display: block;
        border-bottom: 1px solid;
        white-space: normal;

        &:hover {
            color: $colour-ui-light;
        }

        &.is-current {
            text-decoration: none;
        }
    }

    @include media-query(nav) {
        white-space: nowrap;
        padding: 6px 12px;
        display: block;

        .header:not(.header-sticky) & {
            font-weight: $font-weight-normal;
            transition: color $base-anim-speed, background-color $base-anim-speed;

            &:hover {
                color: $colour-ui-light;
                background-color: rgba($colour-primary, .5);
            }
        }
    }
}

.subnav-link-pop {
    .header-sticky & {
        font-weight: $font-weight-medium;
    }
}
