/*------------------------------------*\
    $POST
\*------------------------------------*/

.post-list-header {
    margin-top: 60px - $base-spacing-unit;

    @include media-query(desk) {
        margin-top: 40px - $base-spacing-unit;
        margin-bottom: 34px;

        > :last-child {
            margin-bottom: 0;
        }
    }
}

.post-masonry {
    align-items: flex-start;
}

.post-list {
    margin-left: -21px;
    margin-bottom: 0;

    .slick-list {
        overflow: visible;
    }

    @include media-query(desk) {
        @include grid;
    }

    .slick-arrow {
        position: absolute;
        bottom: -7px;
        transition: opacity $base-anim-speed;

        .icon {
            width: 20px;
            height: 40px;
        }

        &.slick-disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }

    .slick-prev {
        left: 21px;
    }

    .slick-next {
        right: 0;
    }
}

.post {
    display: flex;
    flex-direction: column;
    // flex: 1 1 auto;
    padding: $base-spacing-unit $base-spacing-unit 1px;
    margin-bottom: $base-spacing-unit;
    margin-left: 21px;
    background-color: $colour-ui;
    width: 100%;

    @include media-query(portable) {
        .slick-slider & {
            margin-bottom: 36px;
        }
    }

    @include media-query(desk) {
        @include grid-item(1/3);

        // &:not(:nth-last-child(-n + 3)) {
        //     margin-bottom: 60px;
        // }

        .main-content & {
            @include grid-width(1/2);

            &:not(:nth-last-child(-n + 3)) {
                margin-bottom: $base-spacing-unit;
            }
        }

        .post-masonry & {
            margin-bottom: $base-spacing-unit !important;
        }
    }
}

.post-thumb {
    margin: (0 - $base-spacing-unit) (0 - $base-spacing-unit) $base-spacing-unit;
    display: block;
    overflow: hidden;

    &::before {
        content: "";
        display: block;
        padding-top: 150/240 * 100%;

        .post-youtube & {
            padding-top: 56.25%;
        }

        .post-instagram & {
            padding-top: 100%;
        }
    }

    &:hover  {
        .post-figure {
            transform: scale(1.05);
        }
    }

    .post-youtube &,
    .post-instagram & {
        margin-bottom: -1px;
    }

    .post-youtube & {
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(#000, .2);
        }
    }
}

.post-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 48px;
    height: 48px;
    background-color: $colour-primary;
    color: $colour-ui-light;

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        transform: translate(-50%, -50%);
    }

    .post-instagram & {
        background-color: #3897f0;
    }

    .post-youtube & {
        background-color: red;
        z-index: 1;
    }
}

.post-play {
    width: 48px;
    height: 48px;
    color: $colour-ui-light;
    border: 2px solid;
    border-radius: 100%;
    filter: drop-shadow(0 1px 4px rgba(#000, .1));
    z-index: 1;

    &,
    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &::before {
        content: "";
        border: 8px solid transparent;
        border-left-color: currentColor;
        border-right-width: 0;
        border-left-width: 12px;
        margin-left: 2px;
    }
}

.post-figure {
    transition: opacity $base-anim-speed, transform $base-anim-speed * 1.5;
}

.post-title {
    @include responsive-font(4vw, 16px, 18px);
    line-height: (20px / 18px);
    margin-bottom: 16px;
    font-family: $base-fallback-font;

    .wf-active & {
        font-family: $base-font;
        font-weight: $font-weight-light;
    }
}

.post-footer {
    margin: auto (0 - $base-spacing-unit) -1px;
}

.post-more {
    font-weight: $font-weight-medium;
    color: $colour-primary;
    text-decoration: underline;

    &:hover {
        color: $colour-tertiary;
    }
}
