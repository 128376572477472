/*------------------------------------*\
    $MAIN
\*------------------------------------*/

.main {
    @include media-query(desk) {
        @include grid(70px);
    }
}

.main-content {
    @include media-query(desk) {
        @include grid-item(7/10, 70px);
    }
}

.main-sidebar {
    @include media-query(desk) {
        @include grid-item(3/10, 70px);
    }
}
