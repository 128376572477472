/*------------------------------------*\
    $LEDE
\*------------------------------------*/

.lede {
    font-weight: $font-weight-bold;

    .wf-active & {
        font-weight: $font-weight-medium;
    }
}
