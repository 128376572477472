.filters-root {
    background-color: #f8f8f8;
    padding: 20px;
    &__intro {
        margin-bottom: 10px;
        span {
            font-weight: bold;
            display: block;
            text-align: center;
        }
    }
    .range-wrapper {
        display: flex;
        span {
            display: block;
            &:first-of-type {
                padding-right: 15px;
            }
            &:last-of-type {
                padding-left: 15px;
                min-width: 38px;
            }
        }
        &__input {
            flex: 1;
        }
    }
    .filter_pet-friendly {
        .field-label {
            font-weight: bold;
        }
    }

    // Range input
    input[type="range"] {
        -webkit-appearance: none;
        background: transparent;
        cursor: pointer;
        width: 100%;
        // Track
        &::-webkit-slider-runnable-track {
            background: #fe651f;
            height: 10px;
        }
        &::-moz-range-track {
            background: #fe651f;
            height: 10px;
        }
        // Thumb
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            background: #fe651f;
            border: 5px solid #f5d0c8;
            border-radius: 50%;
            height: 25px;
            width: 25px;
            margin-top: -8px;
        }
        &::-moz-range-thumb {
            background: #fe651f;
            border: 5px solid #f5d0c8;
            border-radius: 50%;
            height: 25px;
            width: 25px;
            margin-top: -8px;
        }
    }
}
