/*------------------------------------*\
    $PAGE
\*------------------------------------*/

.page {
    overflow: hidden;
}

.page-ctas {
    @include media-query(nav-portable) {
        margin-bottom: 47px;
    }
}
