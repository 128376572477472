//------------------------------------*\
//  $SETTINGS-BASE
//------------------------------------*/

// High level base settings
$base-font-size:            14px;
$base-line-height:          24px;
$base-spacing-unit:         $base-line-height;
$base-text-colour:          #004141;
$base-background-colour:    #fff;
$base-fallback-font:        sans-serif;
$base-font:                 "objektiv-mk1", #{$base-fallback-font};
$base-anim-speed:           300ms;


// Colour Palette
// $colour-primary:            #00a6aa;
$colour-primary:            #1f8aac;
$colour-secondary:          #f39200;
$colour-tertiary:           #004365;

$colour-ui-light:           #fff;
$colour-ui:                 #f5f5f5;

$colour-valid:              #0c0;
$colour-invalid:            #c00;

$colour-available:          rgba($colour-tertiary, .5);
$colour-selected:           $colour-ui-light;


// Typefaces
$font-primary:              "Hand of Sean", #{$base-fallback-font};


// Weights
$font-weight-light:         300;
$font-weight-normal:        400;
$font-weight-medium:        500;
$font-weight-bold:          700;


// Animation
$anim-ease-in-out:          cubic-bezier(0.77, 0, 0.175, 1);
$anim-ease-in:              cubic-bezier(0.55, 0.055, 0.675, 0.19);
$anim-ease-out:             cubic-bezier(0.215, 0.61, 0.355, 1);

// Dark theme
$dark-colour-background: #070707;
$dark-colour-container: #191919;
$dark-color-border: #232323;
$dark-colour-typography: #e2e2e2;
$dark-colour-heading: #c29e51;