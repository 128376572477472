/*------------------------------------*\
    $TEAM
\*------------------------------------*/

.team-list {
    @include grid(2px);

    @include media-query(desk) {
        margin-left: -6px;
    }
}

.team {
    @include grid-item(1/2, 2px);
    margin-bottom: 2px;
    overflow: hidden;
    position: relative;

    @include media-query(desk) {
        @include grid-item(1/2, 6px);
        margin-bottom: 21px;
    }
}

.team-figure {
    &::before {
        content: "";
        display: block;
        padding-top: 70%;
    }
}

.team-body {
    position: relative;
    transition: transform $base-anim-speed $anim-ease-in-out;
}

.team-name,
.team-role,
.team-bio {
    text-align: center;
    margin-bottom: 0;
}

.team-name {
    @include font-size(15px, 20px);
    padding: 5px $base-spacing-unit;
    margin-bottom: 0;
    background-color: $colour-primary;
    color: $colour-ui-light;
    font-family: $base-fallback-font;
    font-weight: $font-weight-bold;

    .wf-active & {
        font-family: $base-font;
        font-weight: $font-weight-medium;
    }

    @include media-query(desk) {
        @include font-size(24px, 32px);
        padding-top: 16px;
        padding-bottom: 16px;
    }
}

.team-role {
    background-color: #FAFAFA;
    padding: 3px $base-spacing-unit;

    @include media-query(desk) {
        @include font-size(18px, 26px);
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.team-bio {
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    padding: 74px double($base-spacing-unit) (74px - $base-spacing-unit);
    background-color: $colour-tertiary;
    color: $colour-ui-light;

    @include media-query(portable) {
        max-width:  320px;
        height: 100%;
        z-index: 3;
        transition: transform $base-anim-speed $anim-ease-in;
        text-align: left;

        .team.is-active & {
            transform: translateX(-100%);
            transition-timing-function: $anim-ease-out;
        }
    }

    @include media-query(desk) {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        overflow: auto;
        padding: $base-spacing-unit 32px;
    }
}

.team-toggle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include media-query(desk) {
        display: none;
    }
}

.team-bio-name,
.team-close {
    @include media-query(desk) {
        display: none;
    }
}

.team-close {
    @include font-size(18px, 32px);
    position: absolute;
    left: 21px;
    top: 21px;
    color: $colour-ui-light;
    padding-left: 26px;
    font-weight: $font-weight-medium;

    .icon {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-top: -1px;
    }
}

.team-bio-name {
    @include font-size(16px);
    color: $colour-ui-light;

    .wf-active & {
        font-family: $base-font;
        font-weight: $font-weight-medium;
    }
}
