/*------------------------------------*\
    $WAIT
\*------------------------------------*/

@keyframes wait-rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.wait {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    padding: 20px;
    background-color: rgba($colour-tertiary, .95);
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: opacity $base-anim-speed, visibility 0 $base-anim-speed;

    &.is-active {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
    }
}

.wait-inner {
    background-color: $colour-ui-light;
    padding: $base-spacing-unit;

    &::before {
        content: "";
        margin: 0 auto halve($base-spacing-unit);
        border: 3px solid transparent;
        border-top-color: $colour-primary;
        border-bottom-color: $colour-primary;
        border-radius: 99px;
        width: 50px;
        height: 50px;
        display: block;
        top: 50vh;
        left: 50vw;
        animation: wait-rotate 0.6s infinite linear;
    }
}
