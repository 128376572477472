/*------------------------------------*\
    $SUBHEADING
\*------------------------------------*/

@mixin subheading {
    color: $colour-tertiary;
    font-family: $base-fallback-font;
    font-weight: $font-weight-bold;

    .wf-active & {
        font-weight: $font-weight-medium;
        font-family: $base-font;
    }
}

.subheading {
    @include subheading;
    @include font-size(18px);

    @include media-query(desk) {
        @include font-size(24px, 32px);
    }

    .band-primary & {
        color: $colour-ui-light;
    }
}


.subheading-sml {
    @include font-size(16px);
}
