/*------------------------------------*\
    $CONTAINER
\*------------------------------------*/

/**
 * Contain elements in an arbitrary width
 */

.container {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.container-xmed {
    max-width: 1000px;
}

.container-med {
    max-width: 850px;
}

.container-sml {
    max-width: 600px;
}

.container-xs {
    max-width: 400px;
}
