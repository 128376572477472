/*------------------------------------*\
    $TYPOGRAPHY
\*------------------------------------*/

.mce-content-body,
.typography {
    @include clearfix;

    a:not(.link-bare) {
        font-weight: $font-weight-bold;
        text-decoration: underline;
    }

    @include media-query(desk) {
        @include font-size(16px);
    }
    @include dark-theme {
        color: $dark-colour-typography;
        a {
            color: $dark-colour-heading; 
        }
        h1, h2, h3, h4, h5, h6, .subheading {
            color: $dark-colour-heading;
        }
    }
}

.mce-content-body ul,
.typography ul,
.list {
    > li {
        padding-left: 10px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0.9em;
            left: 0;
            width: 4px;
            height: 4px;
            border-radius: 100%;
            background-color: currentColor;
            transform: translateY(-50%);
        }
    }
}

.mce-content-body table,
.typography table,
.table {
    &[style] {
        width: 100% !important;
    }

    [style] {
        width: auto !important;
    }

    th {
        text-align: left;
    }

    td, th {
        border: 2px solid #eee;
        padding: 4px 8px;

        // &:not(:last-child) {
        //     padding-right: $base-spacing-unit;
        // }

        :last-child {
            margin-bottom: 0;
        }
    }
}

.typography-alt {
    h2, h3, h4 {
        @include subheading;
    }
}

.typography-sml {
    @include media-query(desk) {
        @include font-size($base-font-size);
    }
}

.typography-xs {
    @include font-size(11px, 17px);
}