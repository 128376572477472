/*------------------------------------*\
    $FACILITIES
\*------------------------------------*/

.facilities {
    width: 100%;

    .facilities-header th {
        background-color: $colour-tertiary;
        color: $colour-ui-light;
    }

    th, td {
        border: 1px solid $colour-tertiary;
        padding: 8px 12px;
    }

    td {
        vertical-align: top;

        :last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (max-width: 500px) {
        @include font-size(11px, 1.5);

        th, td {
            padding-left: 8px;
            padding-right: 8px;
        }
    }
}

.facilities-icon {
    @include media-query(lap) {
        width: 64px;
        height: 64px;
    }
}
