/*------------------------------------*\
    $HERO
\*------------------------------------*/

.hero-slider {
    margin-bottom: 0;
}

.hero {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    height: calc(100vh - 112px); // Header + CTA height
    padding-top: $base-spacing-unit; // Offsets bottom margin of title, keeping content centered
    background-color: $colour-tertiary;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 91px;
        background-image: linear-gradient(to top, rgba(#000,.17), transparent);
    }

    @include media-query(nav) {
        height: calc(100vh - 262px); // Header height + extra to show products beneath
    }
}

.hero-figure {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    font-family: 'object-fit: cover';
}

.hero-content,
.hero-action-list {
    @include media-query(portable) {
        display: none;

        .hero-secondary & {
            display: block;
        }
    }
}

.hero-content {
    position: relative;
    z-index: 1;
    text-align: center;

    .hero-secondary & {
        text-align: left;
    }
}
.hero:not(.hero-secondary) .hero-content {
    padding-top:144px;
}

.hero-title,
.hero-action {
    .slick-initialized & {
        transition: opacity 500ms, transform $base-anim-speed;
        opacity: 0;
        transform: translateY(5px);
    }

    .slick-active & {
        opacity: 1;
        transform: none;
    }
}

.hero-title {
    @include responsive-font(4.5vw, 48px, 64px);
    line-height: 1;
    color: $colour-ui-light;
    text-shadow: 0 3px 16px rgba(#000, .75);

    .slick-active & {
        transition-delay: 500ms;
    }

    .hero-secondary & {
        @include responsive-font(9.75vw, 30px, 40px);
        line-height: 1.6;
        text-shadow: none;
    }
}

.hero-action-list {
    position: absolute;
    z-index: 1;
    bottom: 64px;
    left: 0;
    right: 0;
}

.hero-action {
    .slick-active & {
        @for $i from 1 through 3 {
            &:nth-child(#{$i}) {
                transition-delay: (500ms + 150ms * $i);
            }
        }
    }
}

.hero-secondary {
    justify-content: flex-start;
    padding: $base-spacing-unit;

    @include media-query(desk) {
        max-height: 500px;
        padding: 32px double($base-spacing-unit);

        &::before {
            content: "";
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            width: 100%;
            height: 122px;
            background-image: linear-gradient(to bottom, rgba(#000, .17), transparent);
        }
    }
}
