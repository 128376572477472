/*------------------------------------*\
    $FIELD
\*------------------------------------*/

// Define field gutter sizes
$field-gutter-width: $base-spacing-unit !default;

/**
 * Use a containing element for inputs so we can add pseudo-elements
 */

.field {
    margin-bottom: halve($field-gutter-width);
    position: relative;

    .check-list & {
        &:not(:last-child) {
            margin-bottom: quarter($base-spacing-unit);
        }
    }
}

.field-optional {
    &::before {
        content: "(Optional)";
        float: right;
        opacity: 0.5;

        .booking &,
        .field-hide-optional & {
            display: none;
        }
    }
}

.field-check.field-optional::before {
    display: none;
}

.field-med {
    margin-bottom: $base-spacing-unit;
}

.field-label {
    display: inline-block;
    font-weight: $font-weight-medium;
    margin-bottom: 6px;
}

/**
 * Use a class instead of a huge input[type=*] selector
 */

.field-control {
    width: 100%;
    background-color: $colour-ui-light;
    color: $colour-tertiary;
    border: 1px solid;
    border-radius: 3px;
    padding: 11px 20px;
    appearance: none;
    transition: color $base-anim-speed;

    &::placeholder {
        color: currentColor;
        opacity: 1;
        transition: opacity $base-anim-speed;
    }

    &:focus {
        &::placeholder {
            opacity: 0.5;
        }
    }

    .is-invalid & {
        color: $colour-invalid;
    }

    .band-primary &,
    .widget & {
        border-color: transparent;
    }

    @include media-query(palm) {
        .modal & {
            border-color: transparent;
        }
    }
}

.field-datepicker {
    color: $colour-tertiary;
    position: relative;

    .field-control {
        padding-right: double($base-spacing-unit);
    }

    .icon {
        position: absolute;
        top: 50%;
        right: $base-spacing-unit;
        transform: translate(50%, -50%);
        pointer-events: none;
    }
}

.field-select {
    position: relative;

    .field-control {
        padding-right: double($base-spacing-unit);
    }

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: $base-spacing-unit;
        transform: translate(50%, -50%) rotate(45deg);
        margin-top: -2px;
        border-right: 2px solid $colour-tertiary;
        border-bottom: 2px solid $colour-tertiary;
        width: 8px;
        height: 8px;
        pointer-events: none;
    }

    select {
        &::-ms-expand {
            display: none;
        }
    }

    option {
        color: $colour-tertiary;
    }
}

.field-help {
    @include font-size(12px, 1.5);
    margin-top: quarter($base-spacing-unit);
}

.field-feedback {
    @include font-size(12px, 1.5);
    color: $colour-invalid;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height $base-anim-speed $base-anim-speed,
        opacity $base-anim-speed;

    &::before {
        content: "";
        display: block;
        padding-top: quarter($base-spacing-unit);
    }

    .is-invalid > & {
        max-height: 36px; // 12px * 1.5 * 2;
        opacity: 1;
        transition: max-height $base-anim-speed,
            opacity $base-anim-speed $base-anim-speed;
    }

    .band-primary &,
    .widget & {
        color: $colour-ui-light;
    }

    @include media-query(palm) {
        .modal & {
            color: $colour-ui-light;
        }
    }
}

// Switch

.switch-wrapper {
    display: flex;
    align-items: center;
    .label {
        display: block;
        &:first-of-type {
            padding-right: 15px;
        }
        &:last-of-type {
            padding-left: 15px;
        }
    }
    .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 12px;
        input {
            opacity: 0;
            width: 0;
            height: 0;
        }
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: white;
            border: 1px solid #f5d0c8;
            transition: 0.4s;
            &::before {
                position: absolute;
                content: "";
                background: #fe651f;
                border: 5px solid #f5d0c8;
                border-radius: 50%;
                height: 25px;
                width: 25px;
                left: 5px;
                top: -7px;
                transition: 0.4s;
            }
        }
    }
    input:checked + .slider {
        background-color: #fe651f;
    }
    input:focus + .slider {
        box-shadow: 0 0 1px #fe651f;
    }
    input:checked + .slider:before {
        transform: translateX(15px);
    }
}
