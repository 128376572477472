/*------------------------------------*\
    $FOOTER
\*------------------------------------*/

.footer {
    @include media-query(portable) {
        padding: $base-spacing-unit $base-spacing-unit 1px;
    }
}

.footer-heading {
    @include font-size($base-font-size);
    margin-bottom: 17px;
    color: $colour-tertiary;

    .wf-active & {
        font-family: $base-font;
        font-weight: $font-weight-medium;
    }
}

.footer-policies {
    @include media-query(portable) {
        margin-bottom: $base-spacing-unit;
    }
}

.footer-link {
    &,
    &:hover {
        color: $colour-ui-light;
    }

    &.is-active,
    &:hover {
        text-decoration: underline;
    }
}
